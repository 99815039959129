import React from 'react';
import { TextField } from 'src/shared';

export function TextFieldInput (props) {
    return (
        <TextField
            margin="dense"
            required
            fullWidth
            id={props.id}
            label={props.label}
            size="small"
            type={props.type}
            autoFocus={props.autoFocus == true}
            inputProps={{style: {fontSize: 12}}}
            InputLabelProps={{style: {fontSize: 12}}}
            defaultValue={props.defaultValue}
        />
    )
}