class FilterStruct {

    static Languages = {
        ES    : 'Spanish',
        GB    : 'English',
    }

    static Exports = {
        CSV    : 'CSV',
        XLS    : 'XLS',
        PNG    : 'PNG',
        JPEG   : 'JPEG',
    }

    static Level = {
        EconomicGroups  : '0',
        Continental     : '1',
        National        : '2',
    }

    static Years = {
        Year2000    : '2000',
        Year2001    : '2001',
        Year2002    : '2002',
        Year2003    : '2003',
        Year2004    : '2004',
        Year2005    : '2005',
        Year2006    : '2006',
        Year2007    : '2007',
        Year2008    : '2008',
        Year2009    : '2009',
        Year2010    : '2010',
        Year2011    : '2011',
        Year2012    : '2012',
        Year2013    : '2013',
        Year2014    : '2014',
        Year2015    : '2015',
        Year2016    : '2016',
        Year2017    : '2017',
        Year2018    : '2018',
        Year2019    : '2019',
        Year2020    : '2020',
    }

    static YearsESP = {
        Year2004    : '2004',
        Year2005    : '2005',
        Year2006    : '2006',
        Year2007    : '2007',
        Year2008    : '2008',
        Year2009    : '2009',
        Year2010    : '2010',
        Year2011    : '2011',
        Year2012    : '2012',
        Year2013    : '2013',
        Year2014    : '2014',
        Year2015    : '2015',
        Year2016    : '2016',
        Year2017    : '2017',
        Year2018    : '2018',
        Year2019    : '2019',
    }

    static YearsESPDIR = {
        Year2009    : '2009',
        Year2010    : '2010',
        Year2011    : '2011',
        Year2012    : '2012',
        Year2013    : '2013',
        Year2014    : '2014',
        Year2015    : '2015',
        Year2016    : '2016',
        Year2017    : '2017',
        Year2018    : '2018',
        Year2019    : '2019',
        Year2020    : '2020',
    }

    static GLRegions = {
        Canada:'1',
        Chile:'2',
        Mexico:'3',
        UnitedStates:'4',
        Australia:'5',
        Israel:'6',
        Japan:'7',
        Korea:'8',
        NewZealand:'9',
        Austria:'10',
        Belgium:'11',
        CzechRepublic:'12',
        Denmark:'13',
        Estonia:'14',
        Finland:'15',
        France:'16',
        Germany:'17',
        Greece:'18',
        Hungary:'19',
        Iceland:'20',
        Ireland:'21',
        Italy:'22',
        Latvia:'23',
        Lithuania:'24',
        Luxembourg:'25',
        Netherlands:'26',
        Norway:'27',
        Poland:'28',
        Portugal:'29',
        SlovakRepublic:'30',
        Slovenia:'31',
        Spain:'32',
        Sweden:'33',
        Switzerland:'34',
        Turkey:'35',
        UnitedKingdom:'36',
        Albania:'37',
        Armenia:'38',
        Azerbaijan:'39',
        Belarus:'40',
        BosniaandHerzegovina:'41',
        Bulgaria:'42',
        Croatia:'43',
        Cyprus:'44',
        Georgia:'45',
        Gibraltar:'46',
        Kazakhstan:'47',
        Kosovo:'48',
        Kyrgyzstan:'49',
        Malta:'50',
        RepublicofMoldova:'51',
        Montenegro:'52',
        RepublicofNorthMacedonia:'53',
        Romania:'54',
        RussianFederation:'55',
        Serbia:'56',
        Tajikistan:'57',
        Turkmenistan:'58',
        Ukraine:'59',
        Uzbekistan:'60',
        Algeria:'61',
        Angola:'62',
        Benin:'63',
        Botswana:'64',
        Cameroon:'65',
        Congo:'66',
        CotedIvoire:'67',
        DemRepofCongo:'68',
        Egypt:'69',
        Eritrea:'70',
        Ethiopia:'71',
        Gabon:'72',
        Ghana:'73',
        Kenya:'74',
        Libya:'75',
        Mauritius:'76',
        Morocco:'77',
        Mozambique:'78',
        Namibia:'79',
        Niger:'80',
        Nigeria:'81',
        Senegal:'82',
        SouthAfrica:'83',
        SouthSudan:'84',
        Sudan:'85',
        UnitedRepofTanzania:'86',
        Togo:'87',
        Tunisia:'88',
        Zambia:'89',
        Zimbabwe:'90',
        OtherAfrica:'91',
        Bangladesh:'92',
        BruneiDarussalam:'93',
        Cambodia:'94',
        DPRofKorea:'95',
        India:'96',
        Indonesia:'97',
        Malaysia:'98',
        Mongolia:'99',
        Myanmar:'100',
        Nepal:'101',
        Pakistan:'102',
        Philippines:'103',
        Singapore:'104',
        SriLanka:'105',
        ChineseTaipei:'106',
        Thailand:'107',
        VietNam:'108',
        OtherAsia:'109',
        HongKongChina:'111',
        ChinainclHongKongChina:'112',
        Argentina:'113',
        Bolivia:'114',
        Brazil:'115',
        Colombia:'116',
        CostaRica:'117',
        Cuba:'118',
        Curaçao:'119',
        DominicanRepublic:'120',
        Ecuador:'121',
        ElSalvador:'122',
        Guatemala:'123',
        Haiti:'124',
        Honduras:'125',
        Jamaica:'126',
        Nicaragua:'127',
        Panama:'128',
        Paraguay:'129',
        Peru:'130',
        Suriname:'131',
        TrinidadandTobago:'132',
        Uruguay:'133',
        Venezuela:'134',
        OtherNonOECDAmericas:'135',
        Bahrain:'136',
        IslamicRepofIran:'137',
        Iraq:'138',
        Jordan:'139',
        Kuwait:'140',
        Lebanon:'141',
        Oman:'142',
        Qatar:'143',
        SaudiArabia:'144',
        SyrianArabRepublic:'145',
        UnitedArabEmirates:'146',
        Yemen:'147',

    }

    static GLCRegions = {
        Africa:'204',
        Americas:'257',
        Asia:'258',
        Europe:'259',
        Oceania:'260',

    }

    static GLGRegions = {
        ChinainclHongKongChina:'112',
        OECDAmericas:'200',
        OECDAsiaOceania:'201',
        OECDEurope:'202',
        NonOECDEuropeandEurasia:'203',
        Africa:'204',
        AsiaexclChina:'205',
        NonOECDAmericas:'206',
        MiddleEast:'250',

    }

    static EURegions = {
        Austria:'400',
        Belgium:'401',
        Bulgaria:'402',
        Croatia:'403',
        Cyprus:'404',
        Czechia:'405',
        Denmark:'406',
        Estonia:'407',
        Finland:'408',
        France:'409',
        Germany:'410',
        Greece:'411',
        Hungary:'412',
        Iceland:'413',
        Ireland:'414',
        Italy:'415',
        Latvia:'416',
        Lithuania:'418',
        Luxembourg:'419',
        Malta:'420',
        Netherlands:'421',
        Norway:'422',
        Poland:'423',
        Portugal:'424',
        Romania:'425',
        Slovakia:'426',
        Slovenia:'427',
        Spain:'428',
        Sweden:'429',
        UnitedKingdom:'432',
    }

    static EUAllRegions = {
        Austria:'400',
        Belgium:'401',
        Bulgaria:'402',
        Croatia:'403',
        Cyprus:'404',
        Czechia:'405',
        Denmark:'406',
        Estonia:'407',
        Finland:'408',
        France:'409',
        Germany:'410',
        Greece:'411',
        Hungary:'412',
        Iceland:'413',
        Ireland:'414',
        Italy:'415',
        Latvia:'416',
        Lithuania:'418',
        Luxembourg:'419',
        Malta:'420',
        Netherlands:'421',
        Norway:'422',
        Poland:'423',
        Portugal:'424',
        Romania:'425',
        Slovakia:'426',
        Slovenia:'427',
        Spain:'428',
        Sweden:'429',
        Switzerland:'430',
        Turkey:'431',
        UnitedKingdom:'432',
    }

    static EUAllRegionsNone = {
        All : '000',
        Austria:'400',
        Belgium:'401',
        Bulgaria:'402',
        Croatia:'403',
        Cyprus:'404',
        Czechia:'405',
        Denmark:'406',
        Estonia:'407',
        Finland:'408',
        France:'409',
        Germany:'410',
        Greece:'411',
        Hungary:'412',
        Iceland:'413',
        Ireland:'414',
        Italy:'415',
        Latvia:'416',
        Lithuania:'418',
        Luxembourg:'419',
        Malta:'420',
        Netherlands:'421',
        Norway:'422',
        Poland:'423',
        Portugal:'424',
        Romania:'425',
        Slovakia:'426',
        Slovenia:'427',
        Spain:'428',
        Sweden:'429',
        Switzerland:'430',
        Turkey:'431',
        UnitedKingdom:'432',
    }

    static ESPRegions = {
        Andalusia:'300',
        Aragon:'301',
        Asturias:'302',
        Balearics:'303',
        CanaryIslands:'304',
        Cantabria:'305',
        CastileandLeon:'306',
        CastillalaMancha:'307',
        Catalonia:'308',
        Ceuta:'309',
        ValencianCommunity:'310',
        Estremadura:'311',
        Galicia:'312',
        TheRioja:'313',
        Madrid:'314',
        Melilla:'315',
        Navarre:'316',
        BasqueCountry:'317',
        Murcia:'318',
    }

    static ESPRegionsNone = {
        All : '000',
        Andalusia:'300',
        Aragon:'301',
        Asturias:'302',
        Balearics:'303',
        CanaryIslands:'304',
        Cantabria:'305',
        CastileandLeon:'306',
        CastillalaMancha:'307',
        Catalonia:'308',
        Ceuta:'309',
        ValencianCommunity:'310',
        Estremadura:'311',
        Galicia:'312',
        TheRioja:'313',
        Madrid:'314',
        Melilla:'315',
        Navarre:'316',
        BasqueCountry:'317',
        Murcia:'318',
    }

    static ESPDirRegions = {
        Andalusia:'300',
        Aragon:'301',
        Asturias:'302',
        Balearics:'303',
        CanaryIslands:'304',
        Cantabria:'305',
        CastileandLeon:'306',
        CastillalaMancha:'307',
        Catalonia:'308',
        Ceuta:'309',
        ValencianCommunity:'310',
        Estremadura:'311',
        Galicia:'312',
        TheRioja:'313',
        Madrid:'314',
        Melilla:'315',
        Navarre:'316',
        BasqueCountry:'317',
        Murcia:'318',
        International:'319',
    }

    static GLConsSectors = {
        ElectricityandHeatProduction:'100',
        EnergyIndustry:'101',
        ManufacturingandConstruction:'102',
        Transport:'103',
        TransportRoad:'104',
        Residential:'105',
        CommercialandPublicServices:'106',
    }

    static GLProdSectors = {
        Coal:'107',
        Oil:'108',
        Gas:'109',
    }

    static MapTypes = {
        GeographicGroups:'Geographic Groups Map',
        GlobalEmissionsByCountry:'Global Emissions Map by Country',
        EUCountries:'EU Countries Map',
        GeneralEmissionsOfSpanishRegions:'General Emissions of Spanish Regions',
        SpanishAutonomousCommunitiesByRegion:'Spanish Autonomous Communities by Region',
        SpanishAutonomousCommunitiesBySector:'Spanish Autonomous Communities by Sector',
    }

    static EUSectors = {
        Aviation:'10',
        Combustionoffuels:'20',
        Refiningofmineraloil:'21',
        Productionofcoke:'22',
        Metaloreroastingorsintering:'23',
        Productionofpigironorsteel:'24',
        Productionorprocessingofferrousmetals:'25',
        Productionofprimaryaluminium:'26',
        Productionofsecondaryaluminium:'27',
        Productionorprocessingofnonferrousmetals:'28',
        Productionofcementclinker:'29',
        Productionoflimeorcalcinationofdolomitemagnesite:'30',
        Manufactureofglass:'31',
        Manufactureofceramics:'32',
        Manufactureofmineralwool:'33',
        Productionorprocessingofgypsumorplasterboard:'34',
        Productionofpulp:'35',
        Productionofpaperorcardboard:'36',
        Productionofcarbonblack:'37',
        Productionofnitricacid:'38',
        Productionofadipicacid:'39',
        Productionofglyoxalandglyoxylicacid:'40',
        Productionofammonia:'41',
        Productionofbulkchemicals:'42',
        Productionofhydrogenandsynthesisgas:'43',
        Productionofsodaashandsodiumbicarbonate:'44',
        CaptureofgreenhousegasesunderDirective200931EC:'45',
        TransportofgreenhousegasesunderDirective200931EC:'46',
        OtheractivityoptedinunderArt24:'99',
    }

    static ESPSectors = {
        Aluminum:'200',
        Aviation:'201',
        Wallandfloortiles:'202',
        Lime:'203',
        Cement:'204',
        Combustion:'205',
        Ceramics:'206',
        Generationwithcoal:'208',
        Generationwithcombinedcycle:'209',
        Extrapeninsulargeneration:'211',
        Pulpandpaper:'212',
        FerricMetalProcessing:'213',
        Nonferrousmetalprocessing:'214',
        Oilrefining:'215',
        Ironandsteelindustry:'216',
        Bricksandrooftiles:'217',
        Glass:'218',
    }

    static ETSSectors = {
        Energy:'300',
        IndustrialProcessandProductUse:'301',
        Agriculture:'302',
        LandUseandForestry:'303',
        WasteManagement:'304',
        RemainingSectors:'305',
        EnergyIndustries:'306',
        ManufacturingandConstructionIndustries:'307',
        Transport:'308',
        OtherSectors:'309',
        RemainingEnergy:'310',
    }
}

export default FilterStruct
