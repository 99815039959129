import React from 'react';
import ReactDOM from 'react-dom/client';
import "leaflet/dist/leaflet.css";
import '@toast-ui/chart/dist/toastui-chart.min.css';
import App from './App';

// Material UI components: https://mui.com/material-ui/react-button/
export { default as Button } from '@mui/material/Button';

const root = ReactDOM.createRoot(document.getElementById('react-root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);