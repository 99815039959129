import React, { useState } from 'react'
import '@toast-ui/chart/dist/toastui-chart.css'
import '@toast-ui/chart/dist/toastui-chart.min.css'
import { ColumnChart } from '@toast-ui/react-chart'
import { VisualisationDimensions,convertSingletonToArray,convertSeriesDataToFloat, _ } from 'src/utils'
import { MultiChartSelector, Analytics } from 'src/shared'

function MultiColumnChart(props) {
    const charts = Object.keys(props.data)
    const [selected, set_selected] = useState(charts[0])

    if (charts.indexOf(selected) == -1){
        set_selected(charts[0])
    }

    let on_select = (new_selection) => set_selected(new_selection)

    let w = props.w != null ? props.w : '90vw'
    let h = props.h != null ? props.h : '55vh'

    return (
        <div className='multichart-container'>

            <MultiChartSelector 
                charts={charts}
                selected={0}
                on_select={on_select} />

            <MultiColumnChartVisualisationContainer 
                key={selected}
                data={props.data[selected]} 
                sub_category={props.sub_category} w={w} h={h}/>
            
        </div>
    )
}

function MultiColumnChartVisualisationContainer(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const ref = VisualisationDimensions(w, h, set_width, set_height);

    return (
        <React.Fragment>

            <div ref={ref} className='multichart-visualisation-container'>
                <MultiColumnChartVisualisation data={props.data} width={w} height={h} w={props.w} h={props.h}/>
            </div>
            
            <div style={{
                    display: 'flex', 
                    flexDirection: 'column'}}>

                {props.sub_category}

                {Object.hasOwn(props.data, "analytics") ? <Analytics analytics={props.data.analytics} /> : <></>}
                
            </div>

        </React.Fragment>
    )
}

function MultiColumnChartVisualisation(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: props.data.title,
        },
        yAxis: {
           title: { text: props.data.metadata?.units }
        },
        // xAxis: {
        //    title: { text: props.data.metadata?.xunits }
        // },
        theme: 'gea-theme'

    }
    const containerStyle = {
        width: props.w,
        height: props.h,
      };

    let data = typeof(props.data.data['series'][0]['data']) == 'object' ? convertSeriesDataToFloat(props.data.data) : convertSingletonToArray(props.data.data)

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    return <ColumnChart key={key} data={data} options={options} style={containerStyle}/>
}


export default MultiColumnChart
