import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { _ } from 'src/utils';

function Dropdown(props) {
    let label = props.label ? props.label : '';
    let textColorOverride = props.color || null;
    let borderColorOverride = props.color || 'primary.main';

    const FormControlComponent = styled(FormControl)(({ theme }) => ({
        minWidth: "125px",
        marginLeft: "10px",
        "& .MuiInputLabel-asterisk": {
            display: "none"
        },
        "& .MuiInputLabel-root": {
            color: textColorOverride
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid',
            borderColor: borderColorOverride
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '2px solid',
            borderColor: borderColorOverride
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '2px solid',
            borderColor: borderColorOverride
        }
    }));

    const SelectComponent = styled(Select)(({ theme }) => ({
        width: "100%",
        fontSize: "12px"
    }));

    const menuProps = {
        PaperProps: {
            sx: {
                borderRadius: "8px !important",
                width: "min-content",
                maxHeight: "400px",
                backgroundColor: "#F2F8F4",
            },
        },
        MenuListProps: {
            sx: {
                "& li": {
                    fontWeight: 200,
                    paddingTop: 8,
                    paddingBottom: 8,
                    fontSize: "12px",
                }
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    };

    const handleChange = (event) => {
        props.on_change(event.target.value);
    };

    return (
        <FormControlComponent required size="small">
            <InputLabel
                id="select-input-label"
                sx={{
                    fontSize: 12,
                    marginTop: '2px'
                }}>
                {label}
            </InputLabel>

            <SelectComponent
                label={label}
                value={props.value}
                onChange={handleChange}
                MenuProps={menuProps}
                disabled={props.disabled}
                autoWidth
            >
                {drop_down_options(props.key_value_map, props.display_values)}
            </SelectComponent>
        </FormControlComponent>
    );
}

/** @returns The HTML options tags for the options in the given key-value map. */
function drop_down_options(key_value_map, display_values) {
    return Object.keys(key_value_map).map(key => {
        let value = key_value_map[key];
        if (display_values) {
            return <MenuItem key={value} value={key} sx={{ width: "100%", height: 30 }}> {_(value)} </MenuItem>;
        }
        return <MenuItem key={key} value={value} sx={{ width: "100%", height: 30 }}> {_(key)} </MenuItem>;
    });
}

export { Dropdown };