const { REACT_APP_API_URL } = process.env;

class BasketService {

    static async createCheckoutSession(items, csrf) {

        let response = await fetch(REACT_APP_API_URL+"/api/create-checkout-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({'items': items}),
            })
            
        
        if ( response.status == 400) return undefined
        let res = await response.json()
        return res.url
        
        
    }
}

export default BasketService;
