import React from 'react';
import { Divider, List, DescriptionOutlinedIcon, PlaceOutlinedIcon, AirOutlinedIcon, GridViewIcon, RemoveRedEyeOutlinedIcon } from 'src/shared';
import './navigation.css';
import SideNavMenuItem from './side-nav-menu-item';
import Logo from "src/static/img/logos/icon-logo.png";
import LogoFull from "src/static/img/logos/wording-logo.png";
import { _ } from 'src/utils'
import { Drawer } from './side-nav-drawer';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        open: this.props.sideNavOpen,
        currentMenuItemExpanded: null,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.changeCurrentMenuItem = this.changeCurrentMenuItem.bind(this)
  }

  handleDrawerOpen() {
    this.setState({
      open: true
    });
    this.props.handleSideNavChange(true);
  }

  handleDrawerClose() {
    this.setState({
      open: false
    });
    this.props.handleSideNavChange(false);
  }

  changeCurrentMenuItem(title) {
    this.setState({
      currentMenuItemExpanded: title
    });
  }

  render() {
      return (
          <Drawer variant="permanent" open={this.state.open} onMouseEnter={this.handleDrawerOpen} onMouseLeave={this.handleDrawerClose}>
            <Divider />
            <List>
              <div className="logo-container" onClick={() => window.location.href="/"}>
                <img className='logo-image' src={Logo}/>
                {this.state.open ? <img className='logo-image-full' src={LogoFull}/> : null} 
              </div>

              <SideNavMenuItem 
                title={_("Dashboard")} 
                icon={<GridViewIcon />} 
                isDropdown={true}
                subTitles={[_("Global"), _("EU"), _("ESP")]} 
                links={["/dashboard/global", "/dashboard/eu", "/dashboard/esp"]} 
                open={this.state.open}/> 
              
              <SideNavMenuItem 
                title={_("At a Glance")} 
                icon={<RemoveRedEyeOutlinedIcon />} 
                isDropdown={true} 
                subTitles={[ _("Global"), _("EU"), _("ESP")]} 
                links={["/global/glance", "/eu/glance", "/esp/glance"]} 
                open={this.state.open}/> 
              
              <SideNavMenuItem 
                title={_("EU ETS Allowances")} 
                icon={<AirOutlinedIcon />} 
                isDropdown={true} 
                subTitles={[_("Prices"), _("Volume"), _("National Breakdown"), _("Market and Auction")]} 
                links={["/emissions/prices", "/emissions/volume", "/emissions/breakdown", "/emissions/summary"]} 
                open={this.state.open}/> 
              
              <SideNavMenuItem 
                title={_("Maps")} 
                icon={<PlaceOutlinedIcon />} 
                isDropdown={false} 
                links={["/maps"]} 
                open={this.state.open}/>
              
              {/* <SideNavMenuItem 
                title={_("Summaries")} 
                icon={<DescriptionOutlinedIcon />} 
                isDropdown={false} 
                links={["/summaries"]} 
                open={this.state.open}/> */}

            </List>
          </Drawer>
      );
  }
}

export default Navigation;

