import React from 'react';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut in dolor sed neque imperdiet suscipit. Donec suscipit lobortis congue. Fusce vel suscipit eros. Vivamus efficitur auctor erat, eu efficitur libero elementum in. Integer mauris odio, tempor eget massa sed, tempor ullamcorper metus. In ultricies condimentum nisl ut feugiat. Aenean dapibus in tortor eu convallis. Suspendisse potenti. Aenean est arcu, vehicula id molestie sit amet, eleifend nec dolor. Morbi tempor quam sed sem eleifend, vitae viverra mauris aliquet. Mauris rhoncus efficitur tellus sed laoreet. Mauris magna diam, rhoncus nec scelerisque quis, tempor nec neque. Vestibulum rutrum ut est vel rhoncus. Aliquam sodales consequat mauris, et maximus sem fermentum eu. Donec porttitor libero eu vulputate vestibulum.
            </p>
        );
    }
}

export default PrivacyPolicy;
