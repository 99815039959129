import React, {useState } from 'react'
import { _ } from 'src/utils'
import { Tabs, Tab, Button } from '..';

export function TabSelector(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="page-selector-container">
            <div className="page-selector">
                <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                    {props.links?.map((link, i) => 
                        <Tab 
                            key={i} 
                            label={_(props.titles[i])} 
                            onClick={() => props.handleChange(link)}>
                        </Tab>
                    )}
                </Tabs>
            </div>

            {/*<Button 
                disableElevation 
                variant="contained" 
                sx={{
                    borderRadius: '100px', 
                    width: '97px', 
                    height: '39px', 
                    background: '#006E58', 
                    color: '#FFFFFF', 
                    marginLeft: '32px'}}>
                {_("Export")}
                </Button>*/}
        </div>
    )
}

 