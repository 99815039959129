import _ from 'src/utils/i18n'

/** @return The enum with the keys localised -- ONLY USE IN RENDERING! */
function localise_enum(enum_class) {
    let localised = {}

    Object.keys(enum_class).map(key => {
        return localised[_(key)] = enum_class[key]
    })

    return localised
}

function default_start_year(years) {
    const structValues = Object.values(years);
    return structValues[structValues.length-2];
}

function default_end_year(years) {
    const structValues = Object.values(years);
    return structValues[structValues.length-1];
}

export { localise_enum, default_start_year, default_end_year }
