import React from 'react'
import { _ } from 'src/utils'
import { Box, customTheme, InfoIcon, Typography } from 'src/shared';

export function WarningBox(props) {

    return (
        <Box sx={{
            backgroundColor: props.error ? `${customTheme.palette.error.main}` : `${customTheme.palette.warning.main}`, 
            height: 'max-content', 
            width: props.width !== undefined ? props.width : 'max-content', 
            marginLeft: props.marginLeft !== undefined ? props.marginLeft : '0px', 
            marginTop: props.marginTop !== undefined ? props.marginTop : '0px',
            display: 'flex',
            flexDirection: 'row', 
            alignItems: 'center',
            borderRadius: '12px',
            padding: '10px'}}>
                <InfoIcon fontSize='medium' sx={{marginLeft: '6px'}}/>
                <Typography variant="body2" sx={{marginLeft: '6px', marginRight: '10px', marginTop: '2px'}}>
                    {_(props.text)}
                </Typography>
        </Box>
    )
}

 