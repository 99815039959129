const { REACT_APP_API_URL } = process.env;

class AuthenticationService {

    static async getCSRFToken() {
        
        await fetch(REACT_APP_API_URL+"/auth/csrf/", {
                credentials: "include",
            })
            .then((res) => {
                let csrfToken = res.headers.get("X-CSRFToken");
                return csrfToken;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    static async getUserSession() {
        
        await fetch(REACT_APP_API_URL+"/auth/session/", {
                credentials: "include",
            })
            .then((res) => res.json())
            .then((data) => {
                return data;
            })
            .catch((err) => {
                console.error(err);
            });
    }
}

export default AuthenticationService;
