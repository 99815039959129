

export function convertSeriesDataToFloat(data) {
    data.series.forEach(series => {
        series.data = series.data.map(parseFloat);
    });
    return data;
}

export function convertStringToFloat(data) {
    data['series'].forEach(element => {
        element['data'] = parseFloat(element['data'])
    });
    return data
}

export const convertSingletonToArray = (data) => {
    data['series'].forEach(element => {
        element['data'] = [element['data']]
    });
    return data
}