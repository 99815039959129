import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true;

const { REACT_APP_API_URL } = process.env;

/** API class -- a wrapper for axois functions. */
class API {
    static language = undefined;
    static api_url = REACT_APP_API_URL+'/api/'

    /** @param {string} language The language to make API requests in */
    static set_language(language) {
        API.language = language;
    }

    static async getCSRF() {
        const response = await axios.get(API.api_url+'csrf/');
        API.csrf_token = response.data.csrf_token
    }


    /**
     * Make a get request to the GMA API.
     * @async
     * @param {string}      what        What is being requested from the api
     * @param {function}    on_response What to do with the response
     * @param {any}         parameters  GET request parameters
     */
    static async get(what, on_response, parameters={}) {
        if (API.language && !parameters.language)
            parameters.language = API.language;

        // console.info(`[API]\tAGEGET '${API.api_url}${what}'`, parameters);
        await axios.get(API.api_url + what, {params : parameters})
              .then(response => on_response(response))
              .catch(error => on_response(error.response))

        
    }

    /**
    * API GET request where response is returned directly
    * @async
    * @param {string}      what        What is being requested from the api
    * @param {any}         parameters  GET request parameters
    */
    static quickget(what, parameters={}) {
        return axios.get(API.api_url + what, {params : parameters})
            .then(response => response.data)
            .catch(error => console.error(error))
    }

    static setAuth(auth){
        axios.defaults.headers.common['Authorization'] = auth;
    }

    static refreshAuth(){
        refreshToken = localStorage.getItem('refresh_token');
        const data = API.postNoCred('accounts/token/refresh/', {"refresh":refreshToken})
        localStorage.setItem('access_token', data.access);
    }

    /**
    * API POST request where response is returned directly
    * @async
    * @param {string}      what        What is being requested from the api
    * @param {any}         parameters  GET request parameters
    */
    static post(what, parameters={}, config={}) {
        return axios.post(API.api_url + what, parameters,{headers: 
            {'Content-Type': 'application/json'},
             withCredentials: true})
            .then(response => response)
            .catch(error => console.error(error))
    }

    static postNoCred(what, parameters={}, config={}) {
        return axios.post(API.api_url + what, parameters)
            .then(response => response)
            .catch(error => console.error(error))
    }

    /**
    * API Authentication GET request where response is returned directly
    * @async
    * @param {string}      what        What is being requested from the api
    */
    static auth(what){
        return axios.get(API.api_url + what)
            .then(response => response.data)
            .catch(error => console.error(error))
    }
}

export default API;
