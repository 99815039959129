const { REACT_APP_API_URL } = process.env;
import {API} from 'src/utils'

class DashboardService {

    static async getChartData(route, csrf) {

        return fetch(REACT_APP_API_URL+`/api/${route}/?language=${API.language}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
        })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.error(err);
            return 401
        });
    }

}

export default DashboardService;
