const { REACT_APP_API_URL } = process.env;

class UserAccountService {

    static async loginUser(credentials, csrf) {

        return await fetch(REACT_APP_API_URL+"/auth/login/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify(credentials),
          })
    }

    static async registerUser(creds, csrf) {
        return await fetch(REACT_APP_API_URL+"/auth/register/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrf,
          },
          credentials: "include",
          body: JSON.stringify(creds),
        })
        .then((res) => res.json())
        .then((data) => {
          return data.profile !== undefined ? data.profile : data
        })
        .catch((err) => {
          console.error(err);
          return 401
        });
    }

    static async recoverUser(creds, csrf) {
      return await fetch(REACT_APP_API_URL+"/auth/recover/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrf,
        },
        credentials: "include",
        body: JSON.stringify(creds),
      })
      .then((res) => res.json())
      .then((data) => {
        return data.profile !== undefined ? data.detail : data
      })
      .catch((err) => {
        console.error(err);
        return 401
      });
  }

    static async forgotPassword(creds, csrf) {
      return await fetch(REACT_APP_API_URL+"/auth/forgot/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrf,
        },
        credentials: "include",
        body: JSON.stringify(creds),
      })
      .then((res) => res.json())
      .then((data) => {
        return data
      })
      .catch((err) => {
        console.error(err);
        return 401
      });
    }

    static async changeUsersPassword(credentials, csrf) {  
        return await fetch(REACT_APP_API_URL+"/auth/change-password/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify(credentials),
            })
            .then((data) => {
                return data.status
            })
            .catch((err) => {
                console.error(err);
                return 401
        });
    }

    static async handleLogout(handleCurrentViewChange, handleAuthChange) {
        await fetch(REACT_APP_API_URL+"/auth/logout/", {
                credentials: "include",
            })
            .then((response) => {
                if (response.status < 200 || response.status > 299) {
                    throw Error(response.statusText);
                }
                return response.status
            })
            .then((data) => {
                handleCurrentViewChange("Login")
                handleAuthChange(null)
            })
            .catch((err) => {
                console.error(err);
        });
    };

}

export default UserAccountService;
