import React, { useState, useEffect, useRef } from 'react';
import { _, UserAccountService, Storage } from 'src/utils';
import { Button, Box, Link, Typography, TextFieldInput,IconButton,ArrowBackIcon, Loader, InvalidFormState, ApiError } from 'src/shared';
import '../user-account.css';

export default function Register({csrf, handleCurrentViewChange, handleSuccess}) {
    const [loading, set_Loading] = useState(false);
    const [apiError, set_ApiError] = useState(false)
    const [registrationError, set_RegistrationError] = useState(false)
    const [validationError, set_ValidationError] = useState(null)
    const [registrationSuccess, set_RegistrationSuccess] = useState(false)
    const timer = useRef();

    useEffect(() => {
        return () => {
          clearTimeout(timer.current);
        };
    }, []);

    const handleSubmit = async (event) => {
        if (!loading) {
            set_RegistrationSuccess(false);
            set_Loading(true);
            event.preventDefault();
            resetErrStates();
            const data = event.target;
            timer.current = window.setTimeout(async () => {
                const registerResponse = await UserAccountService.registerUser({
                    firstname: data.firstname.value,
                    lastname: data.lastname.value,
                    username: data.username.value,
                    password: data.password.value,
                    confirmedPassword: data.confirmPassword.value,
                    language: Storage.get("language_code")
                }, csrf);
                if (registerResponse == null && registerResponse == undefined) set_ApiError(true);
                else if(registerResponse.error !== null && registerResponse.error !== undefined) set_ValidationError(registerResponse.error)
                else if (registerResponse != null) {
                    set_RegistrationSuccess(true);
                    handleSuccess();
                }
                else set_RegistrationError(true)
                set_Loading(false);
            }, 1000);
        }
    };

    function resetErrStates(){
        set_ApiError(false);
        set_RegistrationError(false);
        set_ValidationError(false);
    }

    return(
        <Box className="register-box box">

            <div className="title-container">
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <IconButton sx={{height: 30}} size="small" onClick={() => handleCurrentViewChange("Login")} disableFocusRipple={true}>
                        <ArrowBackIcon fontSize="inherit" color="primary"/>
                    </IconButton>
                    <Typography variant="h4"> {_("Register")} </Typography>
                </div>
                <Typography variant="h5"> {_("Sign up to get full access")} </Typography>

                <div className="form-break">
                    <span className="link-container">
                        <Typography variant="body2"> {_(" Already have an account?")} &nbsp; </Typography>
                        <Link className="link" variant="body2" onClick={() => handleCurrentViewChange("Login")} >
                            {"Login"}
                        </Link>
                    </span>
                </div>
            </div>

            <Box component="form" onSubmit={handleSubmit} sx={{ mb: 1 }}>

                <TextFieldInput id="firstname" label={_("First Name")} autoFocus={true} type="text"/>
                <TextFieldInput id="lastname" label={_("Last Name")} type="text"/>
                <TextFieldInput id="username" label={_("Email")} type="text"/>
                <TextFieldInput id="password" label={_("Password")} type="password"/>
                <TextFieldInput id="confirmPassword" label={_("Confirm Password")} type="password"/>

                { apiError ? ApiError() : null }
                { registrationError ? <InvalidFormState message={"Your request has failed. Try again"}/> : null }
                { validationError ? <InvalidFormState message={validationError}/> : null }
                { registrationSuccess ? handleCurrentViewChange("Login") : null } 

                <div className="form-break  ts-and-cs">
                    <span className="link-container">
                        <Typography variant="body2"> 
                            {_("By signing up I agree to the GEA")} &nbsp; <Link className="link" variant="body2" target="_blank" href="/terms-of-service">{_("Terms of Service")}</Link>
                            &nbsp; {_("and")} &nbsp; <Link className="link" variant="body2" target="_blank" href="/privacy-policy">{_("Privacy Policy")}</Link>
                        </Typography>                      
                    </span>
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{marginTop: '8px'}}
                    className="submit-button button"
                    disabled={loading}>
                        {_("Sign up")}
                </Button>

                {loading && (<Loader />)}
            </Box>

        </Box>
    );
}