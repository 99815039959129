import React, { useState } from 'react'
import { LineChart } from '@toast-ui/react-chart'
import { VisualisationDimensions,convertSeriesDataToFloat, _ } from 'src/utils'
import { Analytics } from 'src/shared'

function LineGraph(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const ref = VisualisationDimensions(w, h, set_width, set_height);

    return (
        <div className='multichart-container'>
            
            <div ref={ref} className='multichart-visualisation-container'>
                <LineVisualisation data={props.data} width={w} height={h}/>
            </div>

            <div style={{
                    display: 'flex', 
                    flexDirection: 'column'}}>

                {props.sub_category}

                {Object.hasOwn(props.data, "analytics") ? <Analytics analytics={props.data.analytics} /> : <></>}
                
            </div>
            
         </div>
    )
}

function LineVisualisation(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: props.data.title
        },
        xAxis: {
            title: { text: 'Year' }
        },
        yAxis: {
            title: { text: props.data.metadata?.units }
        },
        series: { zoomable: true },
        theme: 'gea-theme'
    }

    const containerStyle = {
        width: '90vw',
        height: '55vh',
      };
    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`


    let data = typeof(props.data.data['series'][0]['data'][0]) == 'number' ? props.data.data : convertSeriesDataToFloat(props.data.data);

    return <LineChart key={key} data={data} options={options} style={containerStyle}/>
}


export { LineGraph }
