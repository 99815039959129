import React, { useState, useEffect } from 'react'
import {_, UserAccountService} from 'src/utils'
import { Button, Box, Link, Typography, TextFieldInput, Loader, InvalidFormState, ApiError } from 'src/shared';
import '../user-account.css';

export default function Login({csrf, handleCurrentViewChange, handleAuthChange, handleSuccess, handleClose}) {
    const [loading, set_loading] = React.useState(false);
    const [apierror, set_apierror] = useState(false);
    const [loginerror, set_loginerror] = useState(false);

    const timer = React.useRef();

    useEffect(() => {
        return () => {
          clearTimeout(timer.current);
        };
    }, []);

    const handleSubmit = async (event) => {
        if (!loading) {
            set_loading(true);
            event.preventDefault();
            resetErrStates();
            const data = event.target;
            timer.current = window.setTimeout(async () => {
                const loginResponse = await UserAccountService.loginUser({
                    username: data.username.value,
                    password: data.password.value
                },
                csrf);
                if (loginResponse == null || loginResponse == undefined) set_apierror(true);
                else if(loginResponse.status == 400) set_loginerror(true)
                else if (loginResponse !== null) {
                    handleSuccess();
                    handleAuthChange(await loginResponse.json());
                    handleClose();
                }
                else set_loginerror(true)
                set_loading(false);
            }, 1000);
        }
    };

    function resetErrStates(){
        set_apierror(false);
        set_loginerror(false);
    }

    return (
        <Box className="login-box box">

            <div className="title-container">

                <Typography variant="h4" mb={1}> {_("Login")} </Typography>
                <Typography variant="h5"> {_("Enter your details below")} </Typography>

                <div className="form-break">
                    <span className="link-container">
                        <Typography variant="body2"> {_("Don't have an account? ")} &nbsp; </Typography>
                        <Link className="link" variant="body2" onClick={() => handleCurrentViewChange("Register")} >
                            {_("Register")}
                        </Link>
                    </span>
                </div>
            </div>

            <Box component="form" onSubmit={handleSubmit}>
                <TextFieldInput id="username" label={_("Email")} autoFocus={true} type="text" />
                <TextFieldInput id="password" label={_("Password")} type="password"/>

                { apierror ? ApiError() : null }
                { loginerror ? <InvalidFormState message={_("Invalid credentials. Try again")}/> : null }
                
                <div className="form-break forgot-password">
                    <Link aria-disabled className="link" variant="body2" onClick={() => handleCurrentViewChange("ForgotPassword")} >
                        {_("Forgot password?")}
                    </Link>
                    
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className="submit-button button"
                        disabled={loading}>
                        {_("Login")}
                    </Button>                        
                </div>      

                {loading && (<Loader />)}

            </Box>
        </Box>
    )
}
