import React from 'react'
import AppRoutes from 'src/app-routing';
import { Loader } from './loader';

export function LoadingPageOrRoutes(props) {

    if (props.loading)
        return (<Loader/>)

    return (
        <AppRoutes 
            auth={props.auth}
            langauge={props.langauge}
            handleAddToBasket={props.handleAddToBasket}
            localised_data={props.localised_data}
            csrf={props.csrf}
        />
    )
}
