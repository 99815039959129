import { MuiDrawer, customTheme } from 'src/shared';
import { styled } from '@mui/material/styles';

const drawerWidth = 360;
const closedDrawerWidth = 88;

const openedMixin = (defaultTheme) => ({
  width: drawerWidth,
  transition: defaultTheme.transitions.create('width', {
    easing: defaultTheme.transitions.easing.sharp,
    duration: 300,
  }),
  overflowX: 'hidden',
  backgroundColor: customTheme.palette.secondary.main
});

const closedMixin = (defaultTheme) => ({
  transition: defaultTheme.transitions.create('width', {
    easing: defaultTheme.transitions.easing.sharp,
    duration: defaultTheme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${closedDrawerWidth}px`,
  [defaultTheme.breakpoints.up('sm')]: {
    width: `${closedDrawerWidth}px`,
  },
  textAlign: 'center',
  backgroundColor: customTheme.palette.secondary.main
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    })
  }),
);
