import React from 'react';
import { Backdrop } from 'src/shared';
import IconLogoGif from "src/static/img/logos/icon-logo.gif";

export function Loader() {
    return (
        <Backdrop 
            open={true} 
            sx={{ 
                backgroundColor: 'transparent', 
                position: 'absolute' 
            }}>

            <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      top: '0',
                      left: '0',
                      opacity: '0.7',
                      backgroundColor: '#fff',
                      zIndex: '99'}}>

                <img style={{zIndex: '100', width: 45, height: 45}} src={IconLogoGif}/>
            </div>

        </Backdrop>
    )
}
