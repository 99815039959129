import React, { useState }  from 'react';
import { _, localise_enum } from 'src/utils';
import FilterStruct, { Popover, IconButton, LanguageOutlinedIcon, MenuItem, Button, Typography } from 'src/shared';
import { styled } from '@mui/material/styles';

export function LanguageSelector(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState(props.selected);

    const Paper = styled('div')(({ theme }) => ({
          borderRadius: "8px !important"
    }));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (key) => {
        setSelected(key);
        props.onSelect(key)
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
        props.handleMouseLeave();
    };

    const open = Boolean(anchorEl);

    const drop_down_options = () => {
        let key_value_map = localise_enum(FilterStruct.Languages);
        return Object.keys(key_value_map).map(key => {
            let value = key_value_map[key]
            if (key === selected)
                return <MenuItem key={key} value={key} selected sx={{width: "100%", height: 35}} onClick={() => handleChange(key)}> {_(value)} </MenuItem>
            return <MenuItem key={key} value={key} sx={{width: "100%", height: 35}} onClick={() => handleChange(key)}> {_(value)} </MenuItem>
        })
    }

    return (
        (<div
            onMouseEnter={() => props.handleMouseEnter(0)} 
            >
            {props.expanded 
                ? 
                <Button
                    sx={{
                        width: '141px',
                        height: '40px',
                        border: '1px solid #DFDFDF',
                        borderRadius: '111px',
                        color: '#000000'
                    }}
                    onClick={handleClick}>
                        <LanguageOutlinedIcon sx={{marginRight: '8px'}} />
                        <Typography variant="h6">
                            {_("Language")}
                        </Typography>
                </Button> 
                : 
                <IconButton sx={{border: '1px solid #DFDFDF'}} disableFocusRipple={true} size="large">
                    <LanguageOutlinedIcon />
                    
                </IconButton>
            }
            {open ? 
            <Paper>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                >
                    <div 
                        style={{
                            width: '100px', 
                            height: 'max-content',
                            paddingTop: '8px',
                            paddingBottom: '8px'}}>
                            {drop_down_options()}
                    </div>

                </Popover>
            </Paper>
                
            :
            null }
        </div>)
    );
}
