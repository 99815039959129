/**
 * 404.js
 * 
 * @breif   PageNotFoundPage page component
 * @author  Brandon Harris (b.harris.4@warwick.ac.uk)
 */

import React from 'react';

import {_} from '../utils';


/** @returns {JSX} The JSX for the PageNotFoundPage page. */
function PageNotFoundPage(props) {
    return (
        <div className='centre-container'>
            <p></p>
            <i className="far fa-sad-tear fa-404-icon"></i>
            <h1>{_('page_not_found_message')}</h1>
            <p></p>
        </div>
    )
}


export default PageNotFoundPage;
