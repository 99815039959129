import React from 'react'
import { _ } from 'src/utils'
import { Typography, ArrowDropUpIcon, ArrowRightIcon, ArrowDropDownIcon } from 'src/shared';

function PickSymbol(props){
    switch(props.val[1]){
        case 0 : return <ArrowDropUpIcon fontSize='small' />
        case 1 : return <ArrowDropDownIcon fontSize='small' />
        case 2 : return <ArrowRightIcon fontSize='small' />
        default: return null
    }
}

function Analytics(props) {
    return (
        <div style={{
            width: '646px',
            height: 'min-content',
            background: 'rgba(235, 243, 254, 0.21)',
            borderRadius: '24px',
            padding: '24px',
            marginTop: '20px'
        }}>

            <div style={{
                width: '100%',
                height: '30px',
                display: 'flex',
                alignItems: 'center'
            }}>
                <Typography variant="h4"> {_('Did you know?')} </Typography>
            </div>

            {props.analytics?.map((data, i) => 
                <div key={i} style={{
                                width: '100%',
                                minHeight: '30px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: '5px',
                                marginBottom: '5px'
                }}>
                    <PickSymbol val={data} />
                    <Typography variant="body2" sx={{marginLeft: '5px'}}> {_(data[0])} </Typography>
                </div>
            )}
        </div>
    )
}

export default Analytics

