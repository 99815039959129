
export function CategoryTitle(cat) {
    switch(cat) {
        case "eu":
            return "European Union";
        case "esp":
            return "Spain";
        case "global":
            return "Global";
        default:
            return "Global";
      }
}