import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const customTheme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#006E58'
    },
    secondary: {
      main: '#F2F8F4'
    }, 
    warning: {
      main: '#FFFEE7'
    },
    error: {
      main: '#FFE7E7'
    },
    filters: {
      regions: '#3C6E9F',
      sectors: '#33A7CC',
      grouping: '#5A396F' 
    },
    chartSummary: {
      main: '#E6F1C8'
    }
  },
  typography: {
    fontFamily: 'Rubik',
    fontWeightRegular: 300,
    button: {
      textTransform: "none"
    },
    h1: { //Page titles
      fontSize: '45px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '57px',
    },
    h2: {
      fontSize: '39px',
      fontStyle: 'normal',
    },
    h3: {
      fontSize: '31px',
      fontStyle: 'normal',
      fontWeight: '500',
    },
    h4: { //User Accounts Titles
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '24px'
    }, 
    h5 : { //User Account subtitles
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '19px'
    },
    h6 : { 
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '19px'
      },
    body1: { //Most text e.g. Summaries description
      fontSize: '16px', 
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '19px'
    },
    body2: { //Smaller text e.g. Dashboard chart summary description
      fontSize: '13px', 
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '16px'
    }
  }
}));

export { ThemeProvider } from '@mui/material/styles';
export { default as Typography } from '@mui/material/Typography';
export { StyledEngineProvider } from '@mui/material/styles';