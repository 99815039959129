import React from 'react'
import '@toast-ui/chart/dist/toastui-chart.min.css'
import '@toast-ui/chart/dist/toastui-chart.css'
import { LineChart, ColumnChart } from '@toast-ui/react-chart'
import { MultiLineChart, MultiPieChart, MultiColumnChart, MultiTable, LineGraph } from '.'
import { _ } from 'src/utils'

function PricesLineGraph(props) {
    return <MultiLineChart data={props.data}/>
}

function RevenueLineGraph(props) {
    return <MultiLineChart data={props.data} h='37vh'/>
}

function VolumeBarGraph(props) {
    return <MultiColumnChart data={props.data} h='37vh'/>
}

function VolumeRevenue(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: { text: _('EUA Market and Auction') }
        },

        theme: 'gea-theme'
    }

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    return <ColumnChart key={key} data={props.data} options={options}/>
}

function EmissionsLineAreaGraph(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height,
        },
        xAxis: {
            title: { text: _('YEAR') }
        },
        yAxis: {
            title: { text: _('EUROS, MILLIONS OF') }
        },
        // series: { zoomable: true },
        theme: 'gea-theme'
    }

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    return <LineChart key={key} data={props.data} options={options}/>
}

function EmissionsMultiPieChart(props) {
    return <MultiPieChart data={props.data}/>
}

function EmissionsSummaryTable(props) {
    return <MultiTable data={props.data}/>
}

export {
    PricesLineGraph,
    RevenueLineGraph,
    VolumeBarGraph,
    VolumeRevenue,
    EmissionsLineAreaGraph,
    EmissionsMultiPieChart,
    EmissionsSummaryTable
}
