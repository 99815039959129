import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom';
import {API, i18n, Storage} from './utils';
import { TopLevel } from './views';
import { ThemeProvider, StyledEngineProvider, customTheme } from 'src/shared';
import './static/css/style.css';

/** App class. */
class App extends React.Component {

    /** App constructor, intialise storage and language. */
    constructor(props) {
        super(props);
        Storage.initialise(i18n.get_user_language_code());
        this.state = {
            testGet: 'KO',
            auth: null,
        }
        this.handleAuthChange = this.handleAuthChange.bind(this)
    } 
    
    componentDidMount() {
        this.set_language(Storage.get('language_code'));
    }

    /**
     * Set local language, update html lang, and fetch localised data from API.
     * @param {string} language The new page language.
     */
    set_language(language) {
        Storage.set('language_code', language);
        API.set_language(language);
        i18n.activate_language(language);

        // Set <html> lang attribute.
        document.getElementsByTagName('html')[0]
                .setAttribute('lang', Storage.get('language_code'));

        this.fetch_localised_data();
    }

    fetch_localised_data() {
        this.locale_data_is_valid = true;
        this.setState({"locale_data":"valid"})
    }

    handleAuthChange() {
        this.setState({
            auth: 1
        })
    }

    /** @returns {JSX} The JSX for the main App. */
    render() {
        return (
            (<div>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={customTheme}>
                        <Router>
                            <TopLevel 
                                locale_data_is_valid={this.locale_data_is_valid} 
                                state={this.state} 
                                change_language_callback={this.set_language.bind(this)}/>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>)
        );
    }
};


export default App;
