import React, { useState, useEffect } from 'react'
import { _ , BasketService, Storage } from 'src/utils'
import BasketItem from './basket-item'
import { MuiDrawer, IconButton, ShoppingBasketOutlinedIcon, Typography, customTheme, 
    Button, Badge, Box, Link, Loader, WarningBox, Tooltip } from 'src/shared';

const { REACT_APP_API_URL } = process.env;

export default function Basket(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    const disableBasket = props.userAccountPlan !== undefined && props.userAccountPlan !== "Basic";
    
    useEffect(() => {
    }, [props.basketItems]);

    const handleClick = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
        props.handleMouseLeave();
    };

    const handleCheckout = async () => {
        setLoading(true);
        setApiError(false);
        let newArray = props.basketItems.map(item => item.id)
        const url = await BasketService.createCheckoutSession(newArray.join(','), props.csrf);
        if(url !== undefined) {
            Storage.set('basket_items', null);
            window.location.href = url;
        }
        else {
            setLoading(false);
            setApiError(true);
        }
    }

    return (
        (<div
            onMouseEnter={() => props.handleMouseEnter(2)} 
            >
            {props.expanded 
                ? 
                <Button
                    sx={{
                        width: '141px',
                        height: '40px',
                        border: '1px solid #DFDFDF',
                        borderRadius: '111px',
                        color: '#000000'
                    }}
                    disabled={disableBasket}
                    onClick={handleClick}>
                        <Badge badgeContent={!disableBasket ? props.basketItems.length : 0} color="primary" sx={{paddingRight: '10px', alignItems: 'center'}}>
                            <ShoppingBasketOutlinedIcon sx={{marginRight: '8px'}}/>
                            <Typography variant="h6">
                                {_("Basket")}
                            </Typography>
                        </Badge>
                </Button> 
                : 
                <IconButton
                    sx={{border: '1px solid #DFDFDF'}}
                    disableFocusRipple={true}
                    disabled={disableBasket}
                    size="large">
                    <Badge badgeContent={!disableBasket ? props.basketItems.length : 0} color="primary">
                        <ShoppingBasketOutlinedIcon />
                    </Badge>
                </IconButton>
            }
            {open ? 
                <MuiDrawer
                    anchor={'right'}
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={{
                        width: '450px', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'flex-start', 
                        padding: '16px',
                        backgroundColor: customTheme.palette.secondary.main, 
                        flexGrow: 1}}>

                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Typography variant="h4" mb={1}> {_("Your Basket")} </Typography>
                        </div>

                        {props.basketItems?.length > 0 
                            ?
                            <Box sx={{ mt: 1, width: '100%' }}>

                                {props.basketItems?.map((item, i) => 
                                    <BasketItem key={i} item={item} handleRemoveFromBasket={props.handleRemoveFromBasket}/>
                                )}
                                
                                <div style={{width: '100%', height: '45px'}}>
                                    <Typography variant="h4" sx={{marginLeft: 'auto', width: 'max-content', marginRight: '8px', marginTop: '16px'}}> 
                                        {`${_('Total: €')}${props.basketItems?.map(i=>i.price).reduce((a,b)=>parseInt(a)+parseInt(b))}`} 
                                    </Typography> 
                                </div>

                                <div style={{width: '100%', marginTop: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <span style={{width: '271px'}}>
                                        <Typography variant="body2"> 
                                            {_("Our payments are processed by Stripe Inc. You will be directed to a new page. ")} &nbsp; 
                                            <Link className="link" variant="body2" target="_blank" href="/terms-of-service">{_("Read full T&Cs here")}</Link>
                                        </Typography>                      
                                    </span>

                                    <Tooltip
                                        title={!props.auth ? _(`Please login to purchase charts`) : ''}
                                        arrow
                                        placement="top">
                                        <span>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="submit-button button"
                                                onClick={handleCheckout}
                                                disabled={!props.auth}>
                                                    {_("Checkout")}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </div>

                            </Box> 
                            :
                            <Typography variant="body1" color="text.secondary" mt={2}>
                                {_("Your basket is currently empty. Add an item from the Dashboard to purchase it")} 
                            </Typography>
                        }

                        {loading && (<Loader />)}

                        {apiError && (
                            <WarningBox 
                                error={true}
                                width={'90%'}
                                marginTop={'20px'}
                                text={_("Could not connect to Stripe Payments. Please contact Fundación Empresa y Clima")} />
                        )}

                    </Box>

                </MuiDrawer>
            :
            null }
        </div>)
    );
}