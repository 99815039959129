 import React, { useState } from 'react'
 import '@toast-ui/chart/dist/toastui-chart.css'
 import '@toast-ui/chart/dist/toastui-chart.min.css'
 import { PieChart, BarChart } from '@toast-ui/react-chart'
 import { Choropleth, MultiLineChart, WorldEconomiesChoropleth } from '.'
 import { VisualisationDimensions,convertSingletonToArray,convertSeriesDataToFloat,convertStringToFloat, _ } from 'src/utils'

 function GlobalChoropleth(props) {
     return <Choropleth {...props} width={props.width} height={props.height}/>
 }

 function GlobalKaya(props) {
     return <MultiLineChart data={props.data}/>
 }

 function GlobalC02Summary(props) {
     const [w, set_width]  = useState(0);
     const [h, set_height] = useState(0);
     const ref = VisualisationDimensions(w, h, set_width, set_height);

     return (
         <React.Fragment>
            <div ref={ref} className='global-glance-page-grid'>
               <div ref={ref} className='global-glance-page-visualisation-container'>
                     < GlobalCO2 data={props.data} width={w/2} height={h/2} />
                     < GlobalCap data={props.data} width={w/2} height={h/2} />
               </div>
               <div ref={ref} className='global-glance-page-grid-long'>
                     < Improvement data={props.data} width={w} height={h/2} />
               </div>
            </div>
         </React.Fragment>
     )
 }

 function GlobalCO2(props) {
     const options = {
         chart: {
             width: props.width,
             height: props.height,
             title: { text: _(props.data.CO2.title) }
         },
         series: { zoomable: true },
         theme: 'gea-theme'
     }

     // Unique key to force re-render on resize
     const key = `${props.width}${props.height}`

     const containerStyle = {
        width: '45vw',
        height: '38vh',
      };

     let data = typeof(props.data.CO2.data['series'][0]['data']) == 'number' ? props.data.CO2.data : convertStringToFloat(props.data.CO2.data)

    return <PieChart key={key} data={data} options={options} style={containerStyle}/>
 }

function GlobalCap(props){
     const options = {
         chart: {
             width: props.width,
             height: props.height,
             title: { text: _(props.data.CO2percap.title) }
         },
         theme: 'gea-theme'
     }

     // Unique key to force re-render on resize
     const key = `${props.width}${props.height}`

     const containerStyle = {
        width: '45vw',
        height: '38vh',
      };

     let data = typeof(props.data.CO2percap.data['series'][0]['data']) == 'number' ? props.data.CO2percap.data : convertStringToFloat(props.data.CO2percap.data)

    return <PieChart key={key} data={data} options={options} style={containerStyle}/>
 }

function Improvement(props){
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: { text: _(props.data.Improvement.title) }
        },
        theme: 'gea-theme'
    }

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    const containerStyle = {
        width: '90vw',
        height: '38vh',
      };

    let data = typeof(props.data.Improvement.data['series'][0]['data']) == 'object' ? convertSeriesDataToFloat(props.data.Improvement.data) : convertSingletonToArray(props.data.Improvement.data)

    return <BarChart key={key} data={data} options={options} style={containerStyle} />
}

export {
    GlobalChoropleth,
    GlobalC02Summary,
    GlobalKaya,
}
