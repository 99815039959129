import React, { useEffect, useState } from 'react';
import { _, DashboardService, ApiRequestBuilder, FrozenPreviewChartFilters, CategoryTitle, default_start_year, default_end_year } from 'src/utils'
import { ChartCards } from './chart-cards'
import { Chart } from "./chart"
import FilterStruct, { Loader, IconButton, ArrowCircleLeftOutlinedIcon, Typography, HelpTooltip, DashboardTooltip } from 'src/shared';
import Filter from './filter';

export function Dashboard(props) {

    const [title, set_title] = useState("")
    const [charts, setCharts] = useState(null)
    const [loadingCharts, setLoadingCharts] = useState(false)
    const [selectedChart, setSelectedChart] = useState(null)
    const [loadingSelectedChart, setLoadingSelectedChart] = useState(false)
    const [selectedChartData, setSelectedChartData] = useState(null)

    const [startYear, setStartYear] = useState(default_start_year(FilterStruct.Years))
    const [endYear, setEndYear] = useState(default_end_year(FilterStruct.Years))
    const [regions, setRegions] = useState([])
    const [sectors, setSectors] = useState([])
    const [level, setLevel] = useState(0)
    const [subcat, setSubcat] = useState(null)

    let packedfilter = {
        'start_year': [startYear, setStartYear],
        'end_year': [endYear, setEndYear],
        'regions': [regions, setRegions],
        'sectors': [sectors, setSectors],
        'level': [level, setLevel]
    }
        
    useEffect(() => {
        const asyncFunc = async () => {
            if(selectedChartData !== null) {
                onView(selectedChart);
            }
        }
        asyncFunc()
    }, [subcat, startYear, endYear, regions, sectors, level])

    useEffect(() => {
        const asyncFunc = async () => {
            set_title(CategoryTitle(props.route));
            setSelectedChartData(null);
            setSelectedChart(null);
            setSectors([]);
            setRegions([]);

            refreshCharts();
        }
        asyncFunc()
    }, [props.route])

    useEffect(() => {
        const asyncFunc = async () => {
            refreshCharts();
        }
        asyncFunc()
    }, [props.auth, props.language])

    const refreshCharts = async () => {
        setLoadingCharts(true);
        setCharts(null);
        const charts = await DashboardService.getChartData(props.route, props.csrf);
        setCharts(charts);
        setLoadingCharts(false);
    }

    const onAddToBasket = (selectedChart) => {
        props.handleAddToBasket(selectedChart)
    }

    const onPreview = async (selectedChart) => {
        setLoadingSelectedChart(true);
        let frozenFilter = FrozenPreviewChartFilters(selectedChart.id, selectedChart.type);
        setSelectedChart(selectedChart)
        await ApiRequestBuilder(selectedChart.id, selectedChart.filters, frozenFilter, subcat, setSelectedChartData)
        setLoadingSelectedChart(false);
    }

    const onView = async (selectedChart) => {
        let sub_category = subcat;
        if(Object.hasOwn(selectedChart, 'sub_category') && subcat == null) {
            setSubcat(selectedChart['sub_category'][0]);
            sub_category = selectedChart['sub_category'][0];
        }
        if(!selectedChart.purchased) {
            await onPreview(selectedChart);
            return;
        }
        let frozenFilter = {};
        selectedChart.filters.forEach(element => {
            frozenFilter[element] = (packedfilter[element][0])
        });

        if(Object.hasOwn(frozenFilter,'level') && frozenFilter['level'] != 2){
            delete frozenFilter['regions']
        }
        setLoadingSelectedChart(true);
        setSelectedChart(selectedChart);
        await ApiRequestBuilder(selectedChart.id, selectedChart.filters, frozenFilter, sub_category, setSelectedChartData);
        setLoadingSelectedChart(false);
    }

    const handleBack = () => {
        setSelectedChart(null)
        setSelectedChartData(null)
        setSubcat(null)
    }

    return (
        <div>

            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                {
                    selectedChart
                        ? 
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <IconButton onClick={handleBack} disableFocusRipple={true} size='medium'>
                                <ArrowCircleLeftOutlinedIcon color="primary" sx={{fontSize: 40, fontWeight: 200}} />
                            </IconButton>
                            <Typography variant="h1"> 
                                {_(selectedChart.title)}
                            </Typography> 
                        </div>
                        :
                        <Typography variant="h1"> 
                            {_("Dashboard")} {title === null ? "" :  ` - ${_(title)}`}
                        </Typography>
                }
                {selectedChart ? <HelpTooltip /> : <DashboardTooltip/ >}
            </div>

            <Filter
                category={props.route} 
                setChartData={setSelectedChartData}
                filters={packedfilter}
                selectedChart={selectedChart}
                handleBack={handleBack}
                onAddToBasket={onAddToBasket}
            />

            <div style={{marginBottom: '20px', width: '70%'}}>
                {
                    selectedChart
                        ?
                        <Typography variant="body2"> 
                            {_(selectedChart.description.replace('km2', 'km₂'))}
                        </Typography> 
                        : <></> 
                }
            </div>

            <GraphOrLoading 
                data={charts}
                loadingCharts={loadingCharts} 
                selectedChart={selectedChart}
                chartData={selectedChartData} 
                loadingSelectedChart={loadingSelectedChart} 
                onView={onView} 
                onPreview={onPreview} 
                onAddToBasket={onAddToBasket} 
                subcat={subcat} 
                setSubcat={setSubcat}
                {...props}
            />

        </div>
    )
}

function GraphOrLoading(props) {
    if (props.data === null || props.loadingCharts) {
        return <Loader />
    } else if (props.selectedChart == null && !props.loadingSelectedChart) {
        return <ChartCards {...props} />
    } else {
        //TODO unpack props
        return <Chart {...props} />
    }
}

export default Dashboard;