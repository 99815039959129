import React from 'react'
import '@toast-ui/chart/dist/toastui-chart.css'
import '@toast-ui/chart/dist/toastui-chart.min.css'
import { BarChart, LineChart, ColumnChart } from '@toast-ui/react-chart'
import { Choropleth, SpainRegionsChoropleth } from '.'
import { convertSingletonToArray,convertSeriesDataToFloat,_ } from 'src/utils'

function EspChoropleth(props) {
    return <Choropleth {...props}/>
}

function Espco2Evo(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height/2,
            title: { text: _(props.data.co2Evo.title) }
        },
        xAxis: {
            title: _('Years')
        },
        yAxis: {
            title: _('Percentage Change')
        },
        series: { zoomable: true },
        // theme: 'gea-theme'
    }

    const containerStyle = {
        width: '90vw',
        height: '38vh',
    };
    let data = typeof(props.data.co2Evo.data['series'][0]['data'][0]) == 'number' ? props.data.co2Evo.data : convertSeriesDataToFloat(props.data.co2Evo.data);

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    return <LineChart key={key} data={data} options={options} style={containerStyle}/>
}

function EspTypo(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height/2,
            title: { text: _(props.data.Typo.title) }
        },
        series: {
            stack: {
                type: 'percent'
                },
            zoomable: true
        },
        // theme: 'gea-theme'
    }

    const containerStyle = {
        width: '90vw',
        height: '38vh',
    };

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    let data = typeof(props.data.Typo.data['series'][0]['data']) == 'object' ? convertSeriesDataToFloat(props.data.Typo.data) : convertSingletonToArray(props.data.Typo.data)


    return <BarChart key={key} data={data} options={options} style={containerStyle}/>
}

function EspFacEmi(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height/2,
            title: { text: _(props.data.FacEmi.title) }
        },
        yAxis : {
            title : '%'
        },
        series: { zoomable: true },
        // theme: 'gea-theme'
    }

    const containerStyle = {
        width: '90vw',
        height: '38vh',
    };

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`
    
    let data = typeof(props.data.FacEmi.data['series'][0]['data']) == 'object' ? convertSeriesDataToFloat(props.data.FacEmi.data) : convertSingletonToArray(props.data.FacEmi.data)
   return <ColumnChart key={key} data={data} options={options} style={containerStyle}/>
}

function EspDirEvo(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height/2,
            title: { text: _(props.data.DirEvo.title) }
        },
        xAxis : {
            title : 'Year'
        },
        yAxis : {
            title : 'kt CO2'
        },
        series: { zoomable: true },
        // theme: 'gea-theme'
    }

    const containerStyle = {
        width: '90vw',
        height: '38vh',
    };

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    let data = typeof(props.data.DirEvo.data['series'][0]['data'][0]) == 'number' ? props.data.DirEvo.data : convertSeriesDataToFloat(props.data.DirEvo.data);
    
   return <LineChart key={key} data={data} options={options} style={containerStyle}/>
}

export {
    EspChoropleth,
    EspTypo,
    Espco2Evo,
    EspFacEmi,
    EspDirEvo,
}
