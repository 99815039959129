import L from 'leaflet'

import Aluminum from "src/static/img/icons/200-Aluminum.png"
import Aviation from "src/static/img/icons/201-Aviation.png"
import WallAndFloorTiles from "src/static/img/icons/202-Wall-and-floor-tiles.png"
import Lime from "src/static/img/icons/203-Lime.png"
import Cement from "src/static/img/icons/204-Cement.png"
import Combustion from "src/static/img/icons/205-Combustion.png"
import Ceramics from "src/static/img/icons/206-Ceramics.png"
import GenerationWithBiomass from "src/static/img/icons/207-Generation-with-biomass.png"
import Coal from "src/static/img/icons/208-Coal.png"
import GenerationWithCombinedCycle from "src/static/img/icons/209-Generation-with-combined-cycle.png"
import GenerationWithFuel from "src/static/img/icons/210-Generation-with-fuel.png"
import ExtraPeninsularGeneration from "src/static/img/icons/211-Extra-peninsular-generation.png"
import PulpAndPaper from "src/static/img/icons/212-Pulp-and-paper.png"
import FerricMetalProcessing from "src/static/img/icons/213-Ferric-metal-processing.png"
import NonFerrousMetalProcessing from "src/static/img/icons/214-Non-ferrous-metal-processing.png"
import OilRefining from "src/static/img/icons/215-Oil-refining.png"
import IronAndSteelIndustry from "src/static/img/icons/216-Iron-and-steel-industry.png"
import BricksAndRooftiles from "src/static/img/icons/217-Bricks-and-roof-tiles.png"
import Glass from "src/static/img/icons/218-Glass.png"

let iconsvg = [
    Aluminum,
    Aviation,
    WallAndFloorTiles,
    Lime,
    Cement,
    Combustion,
    Ceramics,
    GenerationWithBiomass,
    Coal,
    GenerationWithCombinedCycle,
    GenerationWithFuel,
    ExtraPeninsularGeneration,
    PulpAndPaper,
    FerricMetalProcessing,
    NonFerrousMetalProcessing,
    OilRefining,
    IronAndSteelIndustry,
    BricksAndRooftiles,
    Glass]


/**
 * findicon method. Assumes spanish sectors ids are between 200 and 220
 *
 * @param   {*}     props   Contains the sector id
 * @returns {JSX}   Leaflet Icon
 */
export function findicon(d, datasum){
    let data = d.data
    const id = d.code-200;
    const size = 5*scaleMarker(data,datasum);
    let icon = L.icon({
        iconUrl: iconsvg[id],
        iconRetinaUrl: iconsvg[id],
        iconSize: [size,size],
    })
    return icon;
}

export function scaleMarker(x,X, min=7, max=30){

    return Math.min(Math.max(100*x/(X),min),max)
}
