const { REACT_APP_API_URL } = process.env;
import {API} from 'src/utils'

class VisualisationService {

    static async getChartData(route, type, on_response) {

        on_response(await this.API_Get(`${route}/${type}`));

    }

    static async API_Get(request) {

        return fetch(REACT_APP_API_URL+`/api/${request}?language=${API.language}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.error(err);
            return 400
        });
    }

}

export default VisualisationService;
