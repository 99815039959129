import React from 'react'
import {Route, Routes} from 'react-router-dom';
import { PageNotFoundPage, EmissionsPage, HomePage, PrivacyPolicy, TermsOfService, 
    SummariesPage, MapsPage, Dashboard } from './views';
import { GlobalGlancePage, EUGlancePage, EspGlancePage } from './views/glance';

function AppRoutes(props) {

    return (
        <Routes>

            {/*Home*/}
            <Route path='/' element={<HomePage localised_data={props.localised_data}/>}/>

            {/*Dashboard*/}
            <Route path='/dashboard/global'
                element = {<Dashboard 
                    auth={props.auth} 
                    langauge={props.langauge} 
                    localised_data={props.localised_data} 
                    csrf={props.csrf} 
                    handleAddToBasket={props.handleAddToBasket} 
                    route={"global"}/>} 
            />
            <Route path='/dashboard/esp'
                element = { <Dashboard 
                    auth={props.auth} 
                    langauge={props.langauge} 
                    localised_data={props.localised_data} 
                    csrf={props.csrf} 
                    handleAddToBasket={props.handleAddToBasket} 
                    route={"esp"}/> }
            />
            <Route path='/dashboard/eu'
                element = { <Dashboard 
                    auth={props.auth} 
                    langauge={props.langauge} 
                    localised_data={props.localised_data} 
                    csrf={props.csrf} 
                    handleAddToBasket={props.handleAddToBasket} 
                    route={"eu"}/> }
            />

            {/*At A Glance*/}
            <Route path='/global/glance'
                element = {<GlobalGlancePage localised_data={props.localised_data}/>}
            />
            <Route path='/eu/glance'
                element = {<EUGlancePage localised_data={props.localised_data}/>}
            />
            <Route path='/esp/glance'
                element = {<EspGlancePage localised_data={props.localised_data}/>}
            />

            {/*Emissions*/}
            <Route path='/emissions/prices'
             element = { <EmissionsPage localised_data={props.localised_data} page={'prices'}/>}
            />
            <Route path='/emissions/volume'
                element = { <EmissionsPage localised_data={props.localised_data} page={'volume'}/>}
            />
            <Route path='/emissions/breakdown'
                element = { <EmissionsPage localised_data={props.localised_data} page={'breakdown'}/>}
            />
            <Route path='/emissions/summary'
                element = { <EmissionsPage localised_data={props.localised_data} page={'summary'}/>}
            />

            {/*Summaries*/}
            {/* <Route path='/summaries'
                <SummariesPage localised_data={props.localised_data}/>
            /> */}

            {/*Maps*/}
            <Route path='/maps'
                element = { <MapsPage localised_data={props.localised_data}/>}
            />

            {/*Privacy policy & Terms*/}
            <Route path='/privacy-policy'
            element = { <PrivacyPolicy/>}
            />
            <Route path='/terms-of-service'
            element = { <TermsOfService/>}
            />

            {/*Other*/}
            <Route path='*'
            element = { <PageNotFoundPage/>}
            />
        </Routes>
    )

}

export default AppRoutes;
