import React, { useState, useEffect } from 'react'
import { _, VisualisationService } from 'src/utils'
import '../glance.css'
import { TabSelector } from 'src/shared'
import { GlobalGlanceGraph } from './global-glance-graph'
import { GraphOrLoading, Typography, HelpTooltip } from 'src/shared'

function GlobalGlancePage() {
    const [page, set_page] = useState('co2')
    const [map, set_map] = useState(null)
    const [co2, set_co2] = useState(null)
    const [kaya, set_kaya] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            await VisualisationService.getChartData('global/glance', 'choropleth', set_map)
            await VisualisationService.getChartData('global/glance', 'co2', set_co2)
            await VisualisationService.getChartData('global/glance', 'kaya', set_kaya)
        }
        fetchData()
    }, [])

    const getData = () => {
        switch (page) {
            case 'map':
                return map;
            case 'kaya':
                return kaya; 
            case 'co2':
                return co2; 
            default:
                return null;
        }
    }

    const handleCurrentViewChange = view => {
        set_page(view);
    };

    return (
        <div>
            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="h1" mb={1}> 
                    {_("At a Glance - Global")} 
                </Typography>
                <HelpTooltip />
            </div>

            <TabSelector 
                titles={[_("CO2 Summary"), _("Choropleth"), _("Kaya Decomposition")]} 
                links={["co2", "map", "kaya"]} 
                handleChange={handleCurrentViewChange}/>

            <GraphOrLoading 
                loading={getData()===null} 
                graph={
                    <GlobalGlanceGraph 
                        data={getData()} 
                        page={page}/>}/>
        </div>
    );
}

export default GlobalGlancePage
