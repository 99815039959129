import React, { useState } from 'react';
import { _, localise_enum } from 'src/utils'
import FilterStruct, { YearsSelector, Dropdown } from 'src/shared'

export function MapsHeader (props) {
    const [mapTypeValue, setMapTypeValue] = useState("GeographicGroups");

    const handleMapTypeChange = (val) => {
        setMapTypeValue(val);
        props.changeTitle(val);
    };

    return (
        <div style={{marginBottom: '20px'}}>

            <Dropdown
                label={_('Select a map')}
                key_value_map={localise_enum(FilterStruct.MapTypes)}
                value={mapTypeValue}
                on_change={handleMapTypeChange}
                display_values={true}
                />
            
            <YearsSelector 
                label={_('Select a year')}
                key_value_map={localise_enum(FilterStruct.Years)}
                start_year={[props.year[0], props.year[1]]}
                marginLeft={'10px'}
                />

            {mapTypeValue == "SpanishAutonomousCommunitiesByRegion" ? 
                <Dropdown
                    label={_('Select sector')}
                    key_value_map={localise_enum(FilterStruct.ESPSectors)}
                    value={props.sector[0]}
                    on_change={props.sector[1]}
                    display_values={false}
                /> : <></>}

            {mapTypeValue == "SpanishAutonomousCommunitiesBySector" ? 
                <Dropdown
                    label={_('Select region')}
                    key_value_map={localise_enum(FilterStruct.ESPRegions)}
                    value={props.region[0]}
                    on_change={props.region[1]}
                    display_values={false}
                /> : <></>}

        </div>
    )
}
  