import React, { useState, useEffect } from 'react'
import {_, UserAccountService} from 'src/utils'
import { Button, Box, IconButton, ArrowBackIcon, Typography, TextFieldInput, Loader, InvalidFormState, ApiError } from 'src/shared';
import '../user-account.css';

export default function ChangePassword({csrf, handleCurrentViewChange, handleSuccess}) {
    const [loading, set_loading] = React.useState(false);
    const [apierror, set_apierror] = useState(false)
    const [requesterror, set_requesterror] = useState(false)
    const [requestsuccess, set_requestsuccess] = useState(false)
    const timer = React.useRef();

    useEffect(() => {
        return () => {
          clearTimeout(timer.current);
        };
    }, []);

    const handleSubmit = async (event) => {
        if (!loading) {
            set_requestsuccess(false);
            set_loading(true);
            event.preventDefault();
            resetErrStates();
            const data = event.target;
            timer.current = window.setTimeout(async () => {
                const authToken = await UserAccountService.changeUsersPassword({
                    old_password: data.oldPassword.value,
                    new_password: data.newPassword.value,
                    csrf
                });
                if (authToken == null && authToken == undefined) set_apierror(true);
                else if (authToken == 200) {
                    set_requestsuccess(true);
                    handleSuccess();
                }
                else set_requesterror(true)
                set_loading(false);
            }, 1000);
        }        
    };

    function resetErrStates(){
        set_apierror(false);
        set_requesterror(false);
    }

    return (
        <Box className="change-password-box box">
            <div className="title-container">
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <IconButton  size="small" onClick={() => handleCurrentViewChange("Summary")} disableFocusRipple={true}>
                        <ArrowBackIcon fontSize="inherit" color="primary"/>
                    </IconButton>
                    <Typography variant="h4"> {_("Change Password")} </Typography>
                </div>
                <Typography variant="h5" mt={1} mb={1}> {_("Confirm your current password and provide a new one")} </Typography>
            </div>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextFieldInput id="oldPassword" label={_("Current Password")} autoFocus={true} type="password"/>
                <TextFieldInput id="newPassword" label={_("New Password")} type="password"/>

                { apierror ? ApiError() : null }
                { requesterror ? <InvalidFormState message={_("Your request has failed. Try again")}/> : null }
                { requestsuccess ? handleCurrentViewChange("Summary") : null } 

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mt: 1, mb: 1 }}
                    className="submit-button button">
                        {_("Submit")}
                </Button>

                {loading && (<Loader />)}

            </Box>
        </Box>
    )
}