import React, { useState } from 'react'
import '@toast-ui/chart/dist/toastui-chart.css'
import '@toast-ui/chart/dist/toastui-chart.min.css'
import { PieChart } from '@toast-ui/react-chart'
import { VisualisationDimensions, convertStringToFloat, _ } from 'src/utils'
import { MultiChartSelector, Analytics } from 'src/shared'

function MultiPieChart(props) {
    const charts = Object.keys(props.data)
    const [selected, set_selected] = useState(charts[0])

    if (charts.indexOf(selected) == -1) {
       set_selected(charts[0])
    }

    let on_select = (new_selection) => set_selected(new_selection)

    return (
        <div className='multichart-container'>

            <MultiChartSelector 
               charts={charts}
               selected={0}
               on_select={on_select} />

            <MultiPieChartVisualisationContainer key={selected} data={props.data[selected]} sub_category={props.sub_category}/>

        </div>
    )
}

function MultiPieChartVisualisationContainer(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const ref = VisualisationDimensions(w, h, set_width, set_height);

    return (
        <React.Fragment>

            <div ref={ref} className='multichart-visualisation-container'>
                <MultiPieChartVisualisation data={props.data} width={w} height={h}/>
            </div>

            <div style={{
                    display: 'flex', 
                    flexDirection: 'column'}}>

                {props.sub_category}

                {Object.hasOwn(props.data, "analytics") ? <Analytics analytics={props.data.analytics} /> : <></>}
                
            </div>
            
        </React.Fragment>
    )
}

function MultiPieChartVisualisation(props) {
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: props.data.title
        },
        legend: {
            align: props.data.data['series'].length > 10 ? 'bottom' : 'right',
        }
    }

    const containerStyle = {
        width: '90vw',
        height: '55vh',
      };

    const key = `${props.width}${props.height}`

    let data = typeof(props.data.data['series'][0]['data']) == 'number' ? props.data.data : convertStringToFloat(props.data.data)

    return <PieChart key={key} data={data} options={options} style={containerStyle}/>
}


export default MultiPieChart
