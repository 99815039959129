import React, { useState } from 'react'
import '@toast-ui/chart/dist/toastui-chart.css'
import '@toast-ui/chart/dist/toastui-chart.min.css'
import { PieChart, BarChart, LineChart } from '@toast-ui/react-chart'
import { Choropleth, EuropeRegionsChoropleth } from '.'
import { VisualisationDimensions,convertSingletonToArray,convertSeriesDataToFloat,convertStringToFloat, _ } from 'src/utils'

function EUChoropleth(props) {
    return <Choropleth {...props}/>
}

function EUTradingChoropleth(props) {
    return <Choropleth {...props}/>
}

function EUTypo(props){
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: { text: _(props.data.title) }
        },
        series: {
          stack: {
            type: 'percent'
          }
        },
        theme: 'gea-theme'
    }

    const containerStyle = {
        width: '90vw',
        height: '76vh',
      };

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    let data = typeof(props.data.data['series'][0]['data']) == 'object' ? convertSeriesDataToFloat(props.data.data) : convertSingletonToArray(props.data.data)

    return <BarChart key={key} data={data} options={options} style={containerStyle} />
}

function EUC02Summary(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const ref = VisualisationDimensions(w, h, set_width, set_height);

    return (
        <React.Fragment>
            <div ref={ref} className='eu-glance-page-grid'>
              <div ref={ref} className='eu-glance-page-grid-long'>
                    < EUEvo data={props.data} width={w} height={h/2} />
              </div>
              <div ref={ref} className='eu-glance-page-visualisation-container'>
                    < EUEts data={props.data} width={w/2} height={h/2} />
                    < EUEtsEnergy data={props.data} width={w/2} height={h/2} />
              </div>
              </div>
        </React.Fragment>
    )
}

function EUEvo(props){
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: { text: _(props.data.co2Evo.title) }
        },
        series: { zoomable: true },
        theme: 'gea-theme'
    }

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    const containerStyle = {
        width: '90vw',
        height: '38vh',
      };

    let data = typeof(props.data.co2Evo.data['series'][0]['data'][0]) == 'number' ? props.data.co2Evo.data : convertSeriesDataToFloat(props.data.co2Evo.data);

    return <LineChart key={key} data={data} options={options} style={containerStyle}/>

}

function EUEts(props){
    const options = {
        chart: {
            width: props.width,
            height: props.height,
            title: { text: _(props.data.ETS.title) }
        },
        theme: 'gea-theme'
    }

    const containerStyle = {
        width: '45vw',
        height: '38vh',
      };

    // Unique key to force re-render on resize
    const key = `${props.width}${props.height}`

    let data = typeof(props.data.ETS.data['series'][0]['data']) == 'number' ? props.data.ETS.data : convertStringToFloat(props.data.ETS.data)

    return <PieChart key={key} data={data} options={options} style={containerStyle}/>

}

function EUEtsEnergy(props){
  const options = {
      chart: {
          width: props.width,
          height: props.height,
          title: { text: _(props.data.ETSEnergy.title) }
      },
      series: { zoomable: true },
      theme: 'gea-theme'
  }

  const containerStyle = {
    width: '45vw',
    height: '38vh',
  };

  // Unique key to force re-render on resize
  const key = `${props.width}${props.height}`

  let data = typeof(props.data.ETSEnergy.data['series'][0]['data']) == 'number' ? props.data.ETSEnergy.data : convertStringToFloat(props.data.ETSEnergy.data)

    return <PieChart key={key} data={data} options={options} style={containerStyle}/>

}


export {
    EUChoropleth,
    EUTradingChoropleth,
    EUTypo,
    EUC02Summary,
}
