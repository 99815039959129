import useDimensions from 'react-cool-dimensions'
import React, { useState, useEffect } from 'react'
/**
 * TUI Chart Issue with auto-resizing, so we get the dimensions of the parent container
 * https://github.com/nhn/tui.chart/issues/595 - thread says resolved but still not working
 */
export function VisualisationDimensions(w, h, set_width, set_height) {
    const { ref, width, height, entry, unobserve, observe } = useDimensions({
        onResize: ({ width, height, entry, unobserve, observe }) => {
            observe();
            if (Math.abs(w-width) > 20) {
               set_width(width)
            }
            if(window.location.href.split('/').at(-1) == 'dashboard') { // Checks whether in dashboard
               if (Math.abs(h-height) > 20) {
                   set_height(height*1.5)
               }
            }
            else {
               if (Math.abs(h-height) > 20) {
                   set_height(height)
               }
            }
        }
    });

    return ref;

    // const [dimensions, setDimensions] = useState({
    //     height: window.innerHeight,
    //     width: window.innerWidth,
    //   });
    
    //   useEffect(() => {
    //     function handleResize() {
    //       setDimensions({
    //         height: window.innerHeight,
    //         width: window.innerWidth,
    //       });
    //     }
    
    //     window.addEventListener('resize', handleResize);
    
    //     // Clean up the event listener on component unmount
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, []); // Empty array ensures that effect is only run on mount and unmount

    //   set_width(dimensions.width)
    //   set_height(dimensions.height)


}