import React, { useEffect, useState } from 'react'
import useDimensions from 'react-cool-dimensions'
import { _ } from 'src/utils'
import {
    GlobalChoropleth,
    GlobalC02Summary,
    GlobalKaya,
} from 'src/visualisations'
import '../glance.css';
import { WarningBox } from 'src/shared';

export function GlobalGlanceGraph(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const { ref, width, height, entry, unobserve, observe } = useDimensions({
        onResize: ({ width, height, entry, unobserve, observe }) => {
            set_width(width)
            set_height(height)
        },
    });

    useEffect(() => {
        getCurrentView();
    }, [props.page])

    const getCurrentView = () => {
        if (props.data === 400) {
            return <WarningBox 
                        error={true}
                        marginLeft={'20px'} 
                        text={_('Your request cannot be fulfilled at the moment. Please try again later')}/>
        }
        switch (props.page) {
            case 'co2':
                return <GlobalC02Summary data={props.data} width={w} height={h} />
            case 'map':
                return <GlobalChoropleth map={"GeographicGroups"} data={props.data} width={w} height={h} override={true} />
            case 'kaya':
                return <GlobalKaya data={props.data} width={w} height={h} />
        }
    }

    return (
        <div ref={ref} className='global-glance-page-visualisation-container-normal'>
            {getCurrentView()}
        </div>
    )
}
