import React from 'react'
import { _ } from 'src/utils'
import WorldImage from '../static/img/backgrounds/world.jpg'
import ForestImage from '../static/img/backgrounds/forest.jpg'
import LeafImage from '../static/img/backgrounds/leaf.jpg'
import OceanImage from '../static/img/backgrounds/ocean.jpg'
import IconLogoGif from "src/static/img/logos/icon-logo.gif";
import WordingLogo from "src/static/img/logos/wording-logo.png";
import DropdownFilter from "src/static/img/site-features/dropdown-filter.JPG";
import SideNavigation from "src/static/img/site-features/side-navigation.JPG";
import TopNavigation from "src/static/img/site-features/top-navigation.JPG";
import './home.css';
import { Typography, Card, CardContent, CardMedia } from 'src/shared'

function HomePage() {
    return (
        <div className="home-page-container">
            <div className="image-container">
                <img style={{filter: 'brightness(70%)', borderRadius: '8px 8px 0px 0px'}} src={WorldImage} alt=""/>
                <div className="first-image-content">
                    <div className="home-logo-container">
                        <div style={{height: '60%', marginLeft: '4%'}}>
                            <img style={{objectFit: 'scale-down', height: '10vh'}} src={IconLogoGif}/>
                        </div>
                        <div>
                            <img style={{maxWidth: '50%'}} src={WordingLogo}/>
                        </div>
                    </div>
                    <Typography variant="h1">
                        {_("Sustainable business starts here.")}
                    </Typography>
                </div>
            </div>
            <div className="image-container">
                <img style={{filter: 'brightness(30%)', height: '80%'}} src={LeafImage} loading="lazy"/>
                <Card sx={{display: 'flex', position: 'absolute', background: 'transparent', width: '75%', height: '50%', boxShadow: 'none'}}>
                    <CardContent sx={{width: '40%', margin: 'auto'}}>
                        <Typography variant="h1" sx={{color: '#FFFFFF'}}>
                            {_("Use GEA's powerful filters to navigate all graphs")}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: '20px', maxHeight: '100%'}}
                            image={DropdownFilter}
                            loading="lazy"
                        />
                    </CardContent>
                </Card>
            </div>
            <div className="image-container">
                <img style={{filter: 'brightness(30%)'}} src={OceanImage} alt="" loading="lazy"/>
                <Card sx={{display: 'flex', position: 'absolute', background: 'transparent', width: '75%', height: '50%', boxShadow: 'none'}}>
                    <CardContent>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: '20px', maxHeight: '100%'}}
                            image={SideNavigation}
                            loading="lazy"
                        />
                    </CardContent>
                    <CardContent sx={{width: '30%', margin: 'auto'}}>
                        <Typography variant="h1" sx={{color: '#FFFFFF'}}>
                            {_("Quickly access all pages via the interactive side navigation")}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            <div className="image-container">
                <img style={{filter: 'brightness(30%)', borderRadius: '0px 0px 8px 8px'}} src={ForestImage} alt="" loading="lazy"/>
                <Card sx={{display: 'flex', position: 'absolute', background: 'transparent', width: '75%', height: '50%', boxShadow: 'none'}}>
                    <CardContent sx={{width: '40%', margin: 'auto'}}>
                        <Typography variant="h1" sx={{color: '#FFFFFF'}}>
                            {_("Use the top navigation to customise your experience")}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: '20px', maxHeight: '100%'}}
                            image={TopNavigation}
                            loading="lazy"
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
        
    )
}

export default HomePage;
