import React from 'react'
import "leaflet/dist/leaflet.css"
import '@toast-ui/chart/dist/toastui-chart.css'
import { CircleMarker, Tooltip, Marker } from 'react-leaflet'
import { _, findicon, scaleMarker } from 'src/utils'
import { SummariesPage } from 'src/views'

export function GenerateSectorMarkers(props) {
  let data
  if (props.data.data.data != undefined) {
    data = props.data.data.data
  } else {

    data = props.data.data
  }
  let metadata
  if (props.data.data.metadata != undefined) {
    metadata = props.data.data.metadata
  } else {

    metadata = props.data.metadata
  }

  let datasum = 0
  data.forEach(d => {
    datasum += parseFloat(d.data)
  });

  return data.map(
    (d, i) =>
    (
      <Marker icon={findicon(d, datasum)} key={d.code} position={d.coords}>
        <Tooltip direction="center" offset={[0, -15]} opacity={1}>
          <b>{d.name}</b> <br /> {Math.round(d.data)} {metadata.units} <br /> {d.secondary} {metadata.units2}
        </Tooltip>
      </Marker>
    )
  )
}

export function GenerateWorldMarkers(props) {
  let data
  if (props.data.data.data != undefined) {
    data = props.data.data.data
  } else {

    data = props.data.data
  }
  let metadata
  if (props.data.data.metadata != undefined) {
    metadata = props.data.data.metadata
  } else {

    metadata = props.data.metadata
  }

  let datasum = 0
  data.forEach(d => {
    datasum += parseFloat(d.data)
  });


  var multi = 3
  if (data.length > 20) multi = 2
  if (metadata.type == _('Emissions per Capita')) multi = 3.4
  return data.map(
    (d, i) =>
    (<CircleMarker key={d.code}
      eventHandlers={{
        click: (event) => {
          props.eventHandler(d.code)
        }
      }} center={d.coords} pathOptions={{ color: d.secondary < 0 ? '#8ABF96' : '#FF6962' }} radius={multi* scaleMarker(d.data, datasum, 2, 25)}>
      <Tooltip direction="center" offset={[0, -15]} opacity={1}>
        <b>{d.name}</b> <br /> {d.data} {metadata.units} <br /> {d.secondary} {metadata.units2}
      </Tooltip>
    </CircleMarker>
    )
  )
}

export function GenerateEUMarkers(props) {
  let data
  if (props.data.data.data != undefined) {
    data = props.data.data.data
  } else {
    data = props.data.data
  }
  let metadata
  if (props.data.data.metadata != undefined) {
    metadata = props.data.data.metadata
  } else {
    metadata = props.data.metadata
  }


  let datasum = 0
  data.forEach(d => {
    datasum += parseFloat(d.data)
  });

  return data.map(
    (d, i) =>
    (<CircleMarker key={d.code} center={d.coords}
      eventHandlers={{
        click: () => {
          props.eventHandler(d)
        }
      }} pathOptions={{ color: d.secondary < 0 ? '#8ABF96' : '#FF6962' }} radius={1.2 * scaleMarker(d.data, datasum)}>
      <Tooltip direction="center" offset={[0, -15]} opacity={1}>
        <b>{d.name}</b> <br /> {d.data} {metadata.units} <br /> {d.secondary} {metadata.units2}
      </Tooltip>
    </CircleMarker>
    )
  )
}

export function GenerateESPMarkers(props) {

  let data
  if (props.data.data.data != undefined) {
    data = props.data.data.data
  } else {
    data = props.data.data
  }


  let metadata
  if (props.data.data.metadata != undefined) {
    metadata = props.data.data.metadata
  } else {
    metadata = props.data.metadata
  }

  let min = undefined
  let multi = 4
  if (metadata.units == _('t CO2')) multi = 6
  if (metadata.type == _('Directive Emissions')) multi = 2
  let datasum = 0
  data.forEach(d => {
    datasum += parseFloat(d.data)
  });

  if (metadata.type == _('Emissions per Capita')){
    multi = 3
    min = 2
  } 

  return data.map(
    (d, i) =>
    (<CircleMarker key={d.code} center={d.coords}
      eventHandlers={{
        click: () => {
          props.eventHandler(d)
        }
      }} pathOptions={{ color: d.secondary < 0 ? '#8ABF96' : '#FF6962' }} radius={multi * scaleMarker(d.data, datasum, min)}>
      <Tooltip direction="center" offset={[0, -15]} opacity={1}>
        <b>{d.name}</b> <br /> {Math.round(d.data)} {metadata.units} <br /> {d.secondary} {metadata.units2}
      </Tooltip>
    </CircleMarker>
    )
  )
}