import React from 'react';
import _ from 'src/utils/i18n';
import { FormControl, Select, InputLabel, Checkbox, Button, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

class ToggleableDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.label = props.label || null;
        this.names = props.names || null;
        this.labels = props.labels || props.names;
        this.maxSelections = props.maxSelections || this.names.length;
        this.state = {
            selectOpen: false,
            valuesSelected: this.names.includes(props.selected[0]) ? props.selected : [],
            showAlert: false,
        };
    }

    setNone = () => {
        this.setState({
            valuesSelected: [],
        });
    };

    handleSelectOpen = () => {
        this.setState({
            selectOpen: true,
        });
    };

    handleCheckboxChecked = (name) => {
        const indexOfName = this.state.valuesSelected.indexOf(name);
        if (indexOfName > -1) {
            this.setState({
                valuesSelected: this.state.valuesSelected.filter((value) => value !== name),
                showAlert: false,
            });
        } else {
            if (this.state.valuesSelected.length < this.maxSelections) {
                this.setState({
                    valuesSelected: this.state.valuesSelected.concat(name),
                });
            } else {
                this.setState({
                    showAlert: true,
                });
            }
        }
    };

    handleSubmit = () => {
        this.setState({
            selectOpen: false,
        });
        this.props.on_change(this.state.valuesSelected);
    };

    checkboxes(names, labels) {
        return names.map((name, index) => {
            const isChecked = this.state.valuesSelected.indexOf(name) > -1;
            const isDisabled = !isChecked && this.state.valuesSelected.length >= this.maxSelections;

            return (
                <div key={name} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    <Tooltip
                        title={isDisabled ? `You can select up to ${this.maxSelections} ${_('options')}` : ''}
                        arrow
                        placement="top"
                    >
                        <span>
                            <Checkbox
                                size="small"
                                checked={isChecked}
                                onClick={() => this.handleCheckboxChecked(name)}
                                disabled={isDisabled}
                            />
                        </span>
                    </Tooltip>
                    <Typography variant="body2"> {labels[index]} </Typography>
                </div>
            );
        });
    }

    render() {
        return (
            <StyledFormControl required size="small" color={this.props.color} disabled={this.props.disabled}>
                <InputLabel
                    id="select-input-label"
                    sx={{
                        fontSize: 12,
                        marginTop: '2px',
                    }}
                    disabled={this.props.disabled}
                >
                    {this.label}
                </InputLabel>

                <Select
                    open={this.state.selectOpen}
                    onOpen={this.handleSelectOpen}
                    onClose={this.handleSubmit}
                    multiple
                    value={this.state.valuesSelected}
                    label={this.label}
                    disabled={this.props.disabled}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: '0px 24px 24px 24px',
                                width: '300px',
                                height: '400px',
                                backgroundColor: '#F2F8F4',
                                fontSize: 12,
                                paddingRight: '12px',
                                paddingBottom: '8px',
                            },
                        },
                        MenuListProps: {
                            sx: {
                                "& li": {
                                    fontWeight: 200,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontSize: "12px"
                                }
                            }
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                    }}
                    renderValue={(selected) => `${selected.filter(Boolean).length} ${_('selected')}`}
                >
                    <div
                        style={{
                            maxHeight: '355px',
                            overflowY: 'auto',
                            marginBottom: '8px',
                        }}
                    >
                        {this.checkboxes(this.names, this.labels)}
                    </div>

                    <div style={{ height: 22, width: '100%' }}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            sx={{
                                float: 'left',
                                marginBottom: '5px',
                                marginLeft: '15px',
                                width: 70,
                                height: 25,
                                fontSize: 12,
                                borderRadius: 100,
                            }}
                            onClick={this.setNone}
                        >
                            {_('Reset')}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            sx={{
                                float: 'right',
                                marginBottom: '5px',
                                width: 70,
                                height: 25,
                                fontSize: 12,
                                borderRadius: 100,
                            }}
                            onClick={this.handleSubmit}
                        >
                            {_('Apply')}
                        </Button>
                    </div>
                </Select>
            </StyledFormControl>
        );
    }
}

const StyledFormControl = styled(FormControl)(({ theme, color, disabled }) => ({
    minWidth: '155px',
    marginLeft: '10px',
    '& .MuiInputLabel-asterisk': {
        display: 'none',
    },
    '& .MuiInputLabel-root': {
        color: disabled || color === null ? theme.palette.primary.main : color,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid',
        borderColor: color === null ? theme.palette.primary.main : color,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '2px solid',
        borderColor: disabled || color === null ? theme.palette.primary.main : color,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid',
        borderColor: color === null ? theme.palette.primary.main : color,
    },
}));

export default ToggleableDropdown;