/**
 * storage.js
 * 
 * @breif   Application session/local storage.
 * @author  Brandon Harris (b.harris.4@warwick.ac.uk)
 */


/** Storage class -- a wrapper for localStorage. */
class Storage {
    /** Initialise the user's local storage. */
    static initialise(language_code) {
        if (this.get('initialised'))
            return
        
        // Set localStorage defaults
        console.warn('[Storage]\tno local storage found, initialising.');
        this.set('initialised', true);
        this.set('language_code', language_code);
        this.set('remember_me', false);
    }

    
    /**
     * Sets the value of the pair identified by key to value, creating a new
     * key-value pair if none existed for key previously.
     * 
     * @param {string} key The name of the item to store.
     * @param {string} value The value of the item to store.
     */
    static set(key, value) {
        if (this.get(key) === null)
            console.info(`[Storage]\t'${key}' added to local storage.`);
        localStorage.setItem(key, value);
    }


    /**
     * Returns the current value associated with the given key, or
     * default_value if the key does not exist in the local storage.
     * 
     * @param {string} key The name of the item to fetch from storage.
     * @param {string} default_value Returned in `key` not in storage.
     */
    static get(key, default_value=null) {
        let value = localStorage.getItem(key);

        if (value === null) {
            //console.warn(`[Storage]\t'${key}' not in local storage.`);
            return default_value;
        }

        return value;
    }


    /** @returns {JSON} The local storage as a JSON object. */
    static to_json() {
        return localStorage;
    }


    /** @returns {string} The string representation of the local storage. */
    static to_string() {
        return JSON.stringify(localStorage);
    }
}


export default Storage;
