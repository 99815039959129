import i18njs from 'roddeh-i18n';

/** @see {@link _} */
var gettext = undefined;

/** @returns {string} Translated form of `text`, as given in ./locale/*.json */
function _(text) {
    return gettext(text)
};

/** i18n class. */
class i18n {
    /** Set the current local language. */
    static activate_language(language_code) {
        let language_data = require(`../locale/${language_code}.json`);
        gettext = i18njs.create(language_data);
    }

    /** @returns {string} ISO 639-1 code of the browser language settings. */
    static get_user_language_code() {
        let language = String(navigator.language || navigator.userLanguage);

        if (language.startsWith('en'))
            return 'en';
        if (language.startsWith('es'))
            return 'es'

        console.warn(`[i18n]\tlanguage code '${language}' is unsupported, `
            `using default ('es')`);
        return 'es';
    }

    /** @returns ISO 639-1 code of the given country code. */
    static get_language_code_from_country_code(country_code) {
        if (country_code === 'GB')
            return 'en';
        if (country_code === 'ES')
            return 'es';
        
        console.warn(`[i18n]\tno language for unsupported country `
            `'${country_code}', defaulting to 'en'.`);
        return 'en';
    }

    /** @returns {string} Country code of the given ISO 639-1 code. */
    static get_country_code_from_language_code(language_code) {
        if (language_code === 'en')
            return 'GB';
        if (language_code === 'es')
            return 'ES';
        
        console.warn(`[i18n]\tno country for unsupported language `
            `'${language_code}', defaulting to 'GB'.`);
        return 'GB';
    }
}

export default _;
export {i18n};
