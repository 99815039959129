import React, {useState } from 'react'
import { _ } from 'src/utils'
import { Tabs, Tab } from 'src/shared';

export function MultiChartSelector(props) {
    const [value, setValue] = useState(props.selected);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(value>props.charts.length){
        setValue(0)
    }

    return (
            <Tabs 
                value={value} 
                onChange={handleChange} 
                variant={props.charts.length > 2 ? "scrollable" : "fullWidth"}>
                    {props.charts?.map((year, i) => 
                        <Tab 
                            key={i} 
                            label={year} 
                            sx={{minWidth: "fit-content", flex: 1}}
                            onClick={() => props.on_select(year)}>
                        </Tab>
                    )}
            </Tabs>
    )
}

