import React, { useState } from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export function YearsSelector(props) {
    // Initialize the selected years
    let initialYearsSelected = [
        props.end_year
            ? props.end_year[0] > props.start_year[0]
                ? `${props.start_year[0]} - ${props.end_year[0]}`
                : `${props.end_year[0]} - ${props.start_year[0]}`
            : props.start_year[0],
    ];
    let label = props.label || '';
    let multipleYearsSelect = Boolean(props.end_year);

    const [valuesSelected, setValuesSelected] = useState(initialYearsSelected);
    const [open, setOpen] = useState(false);

    const FormControlComponent = styled(FormControl)(({ theme }) => ({
        minWidth: "155px",
        marginLeft: props.marginLeft || 0,
        "& .MuiInputLabel-asterisk": {
            display: "none"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${theme.palette.primary.main}`
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${theme.palette.primary.main}`
        },
    }));

    const menuProps = {
        PaperProps: {
            sx: {
                borderRadius: "0px 24px 24px 24px !important",
                width: "13%",
                height: "max-content",
                fontSize: 12,
                marginLeft: 0,
                backgroundColor: "#F2F8F4",
            },
        },
        MenuListProps: {
            sx: {
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 0,
                display: 'flex',
                flexWrap: 'wrap',
                "& li": {
                    fontWeight: 200,
                    paddingTop: 2,
                    paddingBottom: 2,
                    fontSize: 12,
                }
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
        }
    };

    const handleOpen = () => {
        setValuesSelected([]);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (valuesSelected.length === 0) {
            setValuesSelected(initialYearsSelected);
        } else if (valuesSelected.length === 1 && multipleYearsSelect) {
            setValuesSelected(initialYearsSelected);
        }
    };

    const handleYearClick = (event) => {
        const { value } = event.target;
        setValuesSelected(typeof value === 'string' ? value.split('-') : value);

        if (multipleYearsSelect) {
            if (valuesSelected.length >= 1) {
                setOpen(false);
                props.end_year[1](event.target.value[1]);
            } else {
                props.start_year[1](event.target.value[0]);
            }
        } else {
            setOpen(false);
            props.start_year[1](event.target.value);
        }
    };

    return (
        <FormControlComponent required size="small">
            <InputLabel
                id="select-input-label"
                sx={{
                    fontSize: 12,
                    marginTop: '2px'
                }}>
                {label}
            </InputLabel>

            <Select
                label={label}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                multiple
                value={valuesSelected}
                onChange={handleYearClick}
                MenuProps={menuProps}
                variant="outlined"
                sx={{
                    width: "100%",
                    fontSize: "16px"
                }}
                disabled={props.disabled}
                renderValue={(selected) => `${selected.sort().join(" - ")}`}
                autoWidth
            >
                {drop_down_options(props.key_value_map, valuesSelected)}
            </Select>
        </FormControlComponent>
    );
}

/** @returns The HTML options tags for the options in the given key-value map. */
function drop_down_options(key_value_map, selected) {
    return Object.keys(key_value_map).map(key => {
        let value = key_value_map[key];
        return (
            <MenuItem
                key={key}
                value={value}
                selected={selected.includes(value)}
                sx={{
                    width: 70,
                    height: 30,
                    margin: "2px",
                    borderRadius: "100px",
                    justifyContent: 'center'
                }}
            >
                {key}
            </MenuItem>
        );
    });
}