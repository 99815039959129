import React, { useState, useEffect } from 'react'
import { Popover, IconButton, PersonOutlineOutlinedIcon, Snackbar, MuiAlert, Button, Typography } from 'src/shared';
import './user-account.css';
import { Login, Register, ChangePassword, ForgotPassword, Summary, Recover } from './index';
import { _ } from 'src/utils'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserAccount(props) {
    const [showSnackbar, set_showSnackbar] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentView, setCurrentView] = useState(null);

    useEffect(() => {
        setCurrentView(props.auth === true ? "Summary" : "Login");
        }, [props.auth])
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
        props.handleMouseLeave()
        if (currentView === "ChangePassword") {
            setCurrentView("Summary");
        }
        else if (currentView === "Register" || currentView === "ForgotPassword" || currentView === "Recover") {
            setCurrentView("Login");
        }
    };

    const handleCurrentViewChange = view => {
        setCurrentView(view);
    };

    const handleSuccess = () => {
        set_showSnackbar(true);
    };

    const userAccountViews = {
        "Login": (<Login csrf={props.csrf} handleCurrentViewChange={handleCurrentViewChange} handleSuccess={handleSuccess} handleAuthChange={props.handleAuthChange} handleClose={handleClose}></Login>),
        "Register": (<Register csrf={props.csrf} handleCurrentViewChange={handleCurrentViewChange} handleSuccess={handleSuccess}></Register>),
        "Recover": (<Recover csrf={props.csrf} handleCurrentViewChange={handleCurrentViewChange} handleSuccess={handleSuccess}></Recover>),
        "ForgotPassword": (<ForgotPassword csrf={props.csrf} handleCurrentViewChange={handleCurrentViewChange} handleSuccess={handleSuccess}></ForgotPassword>),
        "ChangePassword": (<ChangePassword csrf={props.csrf} handleCurrentViewChange={handleCurrentViewChange} handleSuccess={handleSuccess}></ChangePassword>),
        "Summary": (<Summary handleCurrentViewChange={handleCurrentViewChange} userAccount={props.userAccount} handleAuthChange={props.handleAuthChange}></Summary>),
    }
  
    const open = Boolean(anchorEl);

    return (
        (<div onMouseEnter={() => props.handleMouseEnter(1)} >
            {props.expanded 
                ? 
                <Button
                    sx={{
                        width: '141px',
                        height: '40px',
                        border: '1px solid #DFDFDF',
                        borderRadius: '111px',
                        color: '#000000'
                    }}
                    onClick={handleClick}>
                        <PersonOutlineOutlinedIcon sx={{marginRight: '8px'}} />
                        <Typography variant="h6">
                            {_("Profile")}
                        </Typography>
                </Button> 
                : 
                <IconButton sx={{border: '1px solid #DFDFDF'}} disableFocusRipple={true} size="large">
                    <PersonOutlineOutlinedIcon />
                </IconButton>
            }
            {open ? 
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    { userAccountViews[currentView] }

                </Popover>
            :
            null }
            {showSnackbar ?
                <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={() => set_showSnackbar(false)} sx={{marginLeft: 10}}>
                    <Alert onClose={() => set_showSnackbar(false)} severity="success" sx={{ width: '100%'}}>
                        {_("Success!")}
                    </Alert>
                </Snackbar>
            :
            null}
        </div>)
    );
}