import React from 'react';
import { Loader } from '../shared';
import { _, i18n, Storage, AuthenticationService } from 'src/utils'
import { Navigation, Header } from 'src/views';
import { LoadingPageOrRoutes, Box, Backdrop } from 'src/shared';
import './top-level.css';

const closedDrawerWidth = 88;
const { REACT_APP_API_URL } = process.env;

class TopLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            csrf : "",
            auth: false,
            userAccount: null,
            sideNavOpen: false,
            basketItems: JSON.parse(Storage.get('basket_items')) ?? [],
            langauge: i18n.get_country_code_from_language_code(
                Storage.get('language_code')
            ),
            change_language_callback: (cc) => {
                let language = i18n.get_language_code_from_country_code(cc);
                props.change_language_callback(language);
                this.state.langauge = language;
            },
            change_login_language_callback: (language) => {
                props.change_language_callback(language);
                this.state.langauge = language;
            }
        };
        this.handleAuthChange = this.handleAuthChange.bind(this)
        this.handleSideNavChange = this.handleSideNavChange.bind(this)
        this.handleAddToBasket = this.handleAddToBasket.bind(this)
        this.handleRemoveFromBasket = this.handleRemoveFromBasket.bind(this)
    }

    componentDidMount = async () => {
        this.getSession();
    }

    getCSRF = async () => {
        const csrfToken = await AuthenticationService.getCSRFToken();
        this.setState({csrf: csrfToken});
    }
    
    getSession = async () => {
        fetch(REACT_APP_API_URL+"/auth/session/", {
            credentials: "include",
          })
          .then((res) => res.json())
          .then((data) => {
            if (data.isAuthenticated) {
              this.setState({auth: true});
              this.setState({userAccount: data.profile});
            } else {
              this.setState({auth: false});
              this.getCSRF();
            }
          })
          .catch((err) => {
            console.error(err);
          });
    }

    async handleAuthChange(userAccount) {
        this.setState({
            auth: !this.state.auth,
            userAccount: userAccount
        });

        if (userAccount) this.state.change_login_language_callback(userAccount.profile.language)
        await this.getSession();
    }

    handleSideNavChange(open) {
        this.setState({
            sideNavOpen: open
        });
    }

    handleAddToBasket(chart) {
        const items = [...this.state.basketItems, chart];
        if(this.state.basketItems.findIndex(x => x.id == chart.id) < 0) {
            this.setState({
                basketItems: items
            });
            Storage.set('basket_items', JSON.stringify(items));
        }
    }

    handleRemoveFromBasket(chart) {
        const items = this.state.basketItems.filter(function(item) { 
            return item.id !== chart.id 
        });
        this.setState({ basketItems: items });
        Storage.set('basket_items', JSON.stringify(items));
    }

    render() {
        if(this.state.csrf === "" && !this.state.auth){
            return <Loader />
        }
        return (
            <Box className="top-level-container">
                <Navigation 
                    sideNavOpen={this.state.sideNavOpen}
                    handleSideNavChange={this.handleSideNavChange} />

                <Box component="main" className="main-container" sx={{width: `calc(99vw - ${closedDrawerWidth}px) !important`, marginLeft: `${closedDrawerWidth}px`}}>

                    <Header 
                        default_country={this.state.langauge}
                        change_language_callback={this.state.change_language_callback}
                        csrf={this.state.csrf} 
                        handleAuthChange={this.handleAuthChange} 
                        auth={this.state.auth}
                        userAccount={this.state.userAccount}
                        basketItems={this.state.basketItems} 
                        handleRemoveFromBasket={this.handleRemoveFromBasket}/>

                    <div className="view-container">
                        <LoadingPageOrRoutes
                            auth={this.state.auth} 
                            langauge={this.state.langauge} 
                            csrf={this.state.csrf}
                            handleAddToBasket={this.handleAddToBasket}
                            loading={!this.props.locale_data_is_valid}
                            localised_data={this.props.state}/>
                    </div>
                </Box>

                {this.state.sideNavOpen 
                && (
                <Backdrop 
                    open={true} 
                    sx={{ 
                        position: 'absolute' 
                    }}>
                </Backdrop>

                )}
            </Box>
        );
    }
}

export default TopLevel;
