import React from 'react';
import { _ } from 'src/utils'
import { ToggleButtonGroup, ToggleButton, CheckIcon } from 'src/shared';

export function ToggleButtonGroupFilter (props) {

    const handleCategoryChange = (event) => {
        props.onCategoryChange(event.target.value);
    };

    return (
        <ToggleButtonGroup
            size="small"
            sx={{marginTop: '25px'}}
            value={props.selected}
            onChange={handleCategoryChange}
            exclusive
            disabled={props.disabled}>

                {props.values?.map((v, i) => 
                    <ToggleButton 
                        key={i} 
                        value={v.value}
                        sx={{
                            borderRadius: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            height: '34px'
                        }}>
                                {props.selected === v.value 
                                    ? <CheckIcon sx={{fontSize:"16px", marginRight: '4px'}}/> 
                                    : <></> 
                                }
                                { _(v.viewValue) }
                    </ToggleButton>
                )}  

        </ToggleButtonGroup>
    )
}
