import React, { useState, useEffect } from 'react'
import './emissions.css';
import { _, VisualisationService } from 'src/utils'
import { Typography, GraphOrLoading, HelpTooltip, WarningBox } from 'src/shared'
import { PricesLineGraph, VolumeBarGraph, RevenueLineGraph, EmissionsMultiPieChart, EmissionsSummaryTable } from 'src/visualisations'

/** @returns {JSX} The JSX for the EmissionsPage page. */
function EmissionsPage(props) {
    const [prices, set_prices] = useState(null)
    const [volume, set_volume] = useState(null)
    const [breakdown, set_breakdown] = useState(null)
    const [summary, set_summary] = useState(null)

    // Fetch all data on first render
    useEffect(() => {
        const fetchData = async () => {
            await VisualisationService.getChartData('eua', 'prices', set_prices)
            await VisualisationService.getChartData('eua', 'volume', set_volume)
            await VisualisationService.getChartData('eua', 'breakdown', set_breakdown)
            await VisualisationService.getChartData('eua', 'summary', set_summary)
        }
        fetchData()
    }, [])

    let data = null
    switch (props.page) {
        case 'prices':
            data = prices; break;
        case 'volume':
            data = volume; break;
        case 'breakdown':
            data = breakdown; break;
        case 'summary':
            data = summary; break;
    }

    return (
        <div>
            <GraphOrLoading 
                loading={data===null} 
                graph={
                    <EmissionsGraphContainer 
                        data={data} 
                        page={props.page}/>}/>
        </div>
    )
}

/** @returns {JSX} The JSX of a populated emissions graph container. */
function EmissionsGraphContainer(props) {

    let title = _("Emissions Allowance Actions");
    let pageName = undefined
    let inner = undefined

    if (props.data === 400) {
        return <WarningBox 
                    error={true}
                    marginLeft={'20px'} 
                    text={_('Your request cannot be fulfilled at the moment. Please try again later')}/>
    }
    
    switch (props.page) {
        case 'prices':
            pageName = `${title} - ${_("Prices")}`;
            inner = <PricesLineGraph data={props.data.primary}/>
            break
        case 'volume':
            pageName = `${title} - ${_("Volume")}`;
            inner = <div>
                <RevenueLineGraph data={props.data.primary}/>
                <VolumeBarGraph data={props.data.secondary}/>
            </div>
            break
        case 'breakdown':
            pageName = `${title} - ${_("National Breakdown")}`;
            inner = <EmissionsMultiPieChart data={props.data.primary}/>
            break

        case 'summary':
            pageName = `${title} - ${_("EUA Market and Auction Values")}`;
            inner = <EmissionsSummaryTable data={props.data.primary}/>
            break
    }

    return (
        <div>
            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="h1" mb={1}> 
                    {_(pageName)} 
                </Typography>
                <HelpTooltip />
            </div>

            {inner}
        </div>
    )
}

export default EmissionsPage
